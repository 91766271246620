<template>
  <v-card border="opacity-50 sm" class="mx-auto" rounded="xl" variant="text">
    <v-row class="ma-1">
      <v-col cols="12" class="border-col">
        <div class="text-h6 text-katal-title font-weight-bold border-col">
          <span v-if="!diasTrabajados">Servicio: </span>
          {{ nombreServicio }}
        </div>
        <div
          class="text-caption text-sm-h6 text-blue-accent-1 font-weight-bold border-col"
        >
          <span v-if="diasTrabajados">Total </span> Carreras: {{ formatoMonedaChile(montoCarrera) }}
          <v-tooltip activator="parent" location="bottom">
            Corresponde al valor cobrado por cada carrera que se realiza
          </v-tooltip>
        </div>
        <div
          class="text-caption text-sm-h6 text-red-accent-1 font-weight-bold border-col"
          v-for="cobro in cobros" :key="cobro"
        >
          {{ cobro.descripcion }}<span v-if="cobro.porcentaje>0">{{ ' '+formatearNumeroComoPorcentaje(cobro.porcentaje) }}</span>: {{ formatoMonedaChile(cobro.valor) }}
          <v-tooltip activator="parent" location="bottom">
            Corresponde a descuentos convenidos con el servicio.
          </v-tooltip>
        </div>
        <div v-if="diasTrabajados"
          class="text-caption text-sm-h6 text-red-accent-1 font-weight-bold border-col"
        >
          Total Descuentos: {{ formatoMonedaChile(montoDescuento) }}
          <v-tooltip activator="parent" location="bottom">
            Corresponde al % de Descuento que realiza el servicio por cada carrera segun lo
            configurado.
          </v-tooltip>
        </div>
      </v-col>
      <v-col cols="12" class="border-col">
        <div
          class="text-h6 text-sm-h5 text-blue-darken-4 font-weight-black border-col"
        >
          <span v-if="diasTrabajados">TOTAL FINAL: </span>
          <span v-if="!diasTrabajados">Total: </span>
          {{ formatoMonedaChile(montoFinal) }}
        </div>
      </v-col>
      <v-col
        cols="6"
        lg="6"
        class="text-caption text-medium-emphasis align-center border-col d-flex justify-space-between"
        v-if="diasTrabajados>0"
      >
        Dias trabajados {{ diasTrabajados }}
      </v-col>
      <v-col cols="6" :lg="diasTrabajados?6:12" class="border-col"
        ><small
          class="text-caption text-medium-emphasis d-flex justify-space-between align-center border-col"
        >
          <div class="text-green border-col">Cantidad de Carreras {{ cantidadCarreras }}</div>
        </small>
      </v-col>
      
    </v-row>
  </v-card>
</template>

<script>
import {formatoMonedaChile,formatearNumeroComoPorcentaje} from '@/helpers/funcionesEstandard';
import buttonSubmitform from '@/components/form/buttonSubmitform.vue';

export default {
  data: () => ({
    formatoMonedaChile: formatoMonedaChile,
    formatearNumeroComoPorcentaje: formatearNumeroComoPorcentaje,
    
  }),
  props:{
    periodo: Number,
    detalle: Number,
    nombreServicio: String,
    montoCarrera: Number,
    montoDescuento: Number,
    montoFinal: Number,
    cantidadCarreras: Number,
    diasTrabajados: Number,
    cobros: Array,
  },
  components:{
    buttonSubmitform
  },
  watch: {
  },
  methods:{
  }
};
</script>

<style>
</style>