<template>
  <div class="text-md-center pa-4">
    <v-dialog v-model="dialog" transition="dialog-top-transition" fullscreen>
      <template v-slot:activator="{ props: activatorProps }">
        <span  class="d-md-block d-none">
        <v-btn
          prepend-icon="mdi-emoticon-outline"
          size="small"
          text="Beneficios"
          v-bind="activatorProps"
        ></v-btn>
        </span> 
        <span class="d-block d-md-none text-caption cursor-pointer" @click="dialog = !dialog">
            <v-icon>mdi-emoticon-outline</v-icon>
            Beneficios</span>
      </template>

      <v-card>
        <v-toolbar class="gradient-background_menu_superior">
          <v-btn icon="mdi-close" @click="dialog = false"></v-btn>

          <v-toolbar-title>
            <span class="text-h5 display-1 font-weight-bold"
              >Beneficios</span
            >
          </v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container class="bg-blue-lighten-5 mt-2">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-title
                >Eficiencia Operativa</v-expansion-panel-title
              >
              <v-expansion-panel-text>
                <v-list lines="one">
                  <v-list-item class="text-caption"
                    >Asigna automáticamente carreras a conductores disponibles, optimizando la utilización de tu flota. Minimiza tiempos de espera y mejora la satisfacción del cliente al ofrecer un servicio más rápido y eficiente.</v-list-item
                  >
                </v-list>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-title
                >Control Total de Gastos</v-expansion-panel-title
              >
              <v-expansion-panel-text>
                <v-list lines="one">
                  <v-list-item class="text-caption"
                    >Registra y categoriza fácilmente todos los gastos asociados con tu flota, desde combustible hasta mantenimiento. Identifica áreas de oportunidad para reducir costos y maximizar la rentabilidad de cada carrera.</v-list-item
                  >
                </v-list>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-title
                >Gestión de Pagos Simplificada</v-expansion-panel-title
              >
              <v-expansion-panel-text>
                <v-list lines="one">
                  <v-list-item class="text-caption"
                    >Procesa pagos de manera rápida y segura a través de nuestra plataforma, eliminando la complejidad de la facturación manual. Genera automáticamente recibos electrónicos para una contabilidad sin complicaciones.</v-list-item
                  >
                </v-list>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-title
                >Control de TAG</v-expansion-panel-title
              >
              <v-expansion-panel-text>
                <v-list lines="one">
                  <v-list-item class="text-caption"
                    >Procesa pagos de manera rápida y segura a través de nuestra plataforma, eliminando la complejidad de la facturación manual. Genera automáticamente recibos electrónicos para una contabilidad sin complicaciones.</v-list-item
                  >
                </v-list>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-title
                >Beneficios Adicionales</v-expansion-panel-title
              >
              <v-expansion-panel-text>
                <v-list lines="one">
                  <v-list-item class="text-caption"
                    >Mayor transparencia en los costos para conductores y operadores. Análisis detallado del rendimiento operativo para una toma de decisiones informada. Experiencia mejorada para los usuarios finales, con tiempos de espera reducidos y un servicio más confiable.</v-list-item
                  >
                  <v-list-item class="text-caption"
                    >No te quedes atrás en la revolución digital del transporte privado. Nuestra plataforma informática está diseñada para llevar tu negocio al siguiente nivel, ofreciendo eficiencia, control financiero y una ventaja competitiva. ¡Haz el cambio hoy y experimenta la transformación en la gestión de carreras de transporte privado.</v-list-item
                  >
                </v-list>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    };
  },
};
</script>
    
    <style>
</style>