<template>
  <v-row>
    <v-col
      cols="12"
      sm="4"
      class="gradient-background rounded-be-circle mx-auto text-wite d-none d-md-block"
      align="center"
    >
      <br />
      <opcionesMenuRapido
        :user="user"
        @SalirSistema="SalirSistema"
      ></opcionesMenuRapido>
    </v-col>
    <!--
      ************************************************************************
      ************************************************************************
      Neesito mostrar
      * Ingresos por servicio
        - Total carreras x conductor
        - Total carrera x Servicio
        - Total descuento
        - Valor final
      ************************************************************************
      ************************************************************************

    -->
    <v-col cols="12" md="8" align="center" justify="center">
      <v-row class="mt-10 ml-5 mr-5 mb-5">
        <v-col cols="12" sm="8" class="border-col">
          Estos son los movimientos que tienes en el período:
        </v-col>
        <v-col cols="12" sm="4" class="border-col">
          <combobox
            labelSet="Período"
            :itemsSet="periodos"
            :valorSet="periodo2"
            @SetValor="cambiarPeriodo"
          ></combobox>
        </v-col>
        <v-col cols="12">
          <v-btn
            class="bg-cyan-darken-1"
            dark
            block
            title
            @click="verDetalleServicio"
            >Ver detalle</v-btn
          >
        </v-col>
        <v-col cols="12" class="border-col">
          <CarreraXPeriodo
            :nombreServicio="Totales.nombre"
            :montoCarrera="Totales.carreras_valor"
            :montoDescuento="Totales.carreras_descuento"
            :montoFinal="Totales.carrera_valor_final"
            :cantidadCarreras="Totales.carreras_cantidad"
            :diasTrabajados="Totales.dias_trabajados"
            :detalle="1"
            :periodo="periodo"
          ></CarreraXPeriodo>
        </v-col>
        <v-col cols="12" class="border-col">
          <resumenPagos
            :totalFinal="Totales.carrera_valor_final"
            :porcentajeConductor="50"
            :valorCarreraEfectivo="Totales.valorCarreraEfectivo"
            :cantidadCarreraEfectivo="Totales.cantidadCarreraEfectivo"
            :cantidadPagos="Totales.cantidadPagos"
            :pagos="Totales.pagos"
          ></resumenPagos>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="border-col"
          v-for="servicio in servicios"
          :key="servicio"
        >
          <CarreraXPeriodo
            :nombreServicio="servicio.nombre"
            :montoCarrera="servicio.carreras_valor"
            :montoDescuento="servicio.carreras_descuento"
            :montoFinal="servicio.carrera_valor_final"
            :cantidadCarreras="servicio.carreras_cantidad"
            :cobros="servicio.cobros"
            :detalle="0"
            :periodo="periodo"
          ></CarreraXPeriodo>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="border-col"
          v-for="combustible in combustibles"
          :key="combustible"
        >
          <CombustibleXPeriodo
            :nombreServicio="combustible.nombre"
            :patente="combustible.patente"
            :valor="combustible.valor"
            :cantidadCargas="combustible.cantidad"
            :detalle="0"
            :periodo="periodo"
          ></CombustibleXPeriodo>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="border-col"
          v-for="gasto in gastos"
          :key="gasto"
        >
          <GastoXPeriodo
            :patente="gasto.patente"
            :valor="gasto.valor"
            :cantidadCargas="gasto.cantidad"
            :detalle="0"
            :periodo="periodo"
          ></GastoXPeriodo>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="border-col"
          v-for="tag in tags"
          :key="tag"
        >
          <TagXPeriodo
            :patente="tag.patente"
            :valor="tag.valor"
            :cantidadCargas="tag.cantidad"
            :detalle="0"
            :periodo="periodo"
          ></TagXPeriodo>
        </v-col>
        <!--v-col cols="12" sm="6" class="border-col">
          <GastosXPeriodo></GastosXPeriodo> 
        </v-col-->
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { obtenerJsonGET } from "@/helpers/funcionesEstandard";
import botonRapido from "@/components/Usuario/principal/botonRapido.vue";
import resumenPagos from "@/components/Usuario/principal/resumenPagos.vue";
import opcionesMenuRapido from "@/components/Usuario/principal/opcionesMenuRapido.vue";
import CarreraXPeriodo from "@/components/carrera/CarreraXPeriodo.vue";
import GastoXPeriodo from "@/components/gastos/GastoXPeriodo.vue";
import CombustibleXPeriodo from "@/components/combustible/CombustibleXPeriodo.vue";
import TagXPeriodo from "@/components/tag/TagXPeriodo.vue";
import combobox from "@/components/form/combobox.vue";

export default {
  data: () => ({
    periodo: null,
    periodo2: null,
    periodos: [],
    servicios: [],
    combustibles: [],
    gastos: [],
    tags: [],
    Totales: {},
  }),
  components: {
    botonRapido,
    opcionesMenuRapido,
    CarreraXPeriodo,
    combobox,
    GastoXPeriodo,
    CombustibleXPeriodo,
    TagXPeriodo,
    resumenPagos,
  },
  props: {
    user: Object,
  },
  mounted: async function () {
    if (!this.user) {
      this.$emit("SalirSistema");
    } else if (!this.user.token) {
      this.$emit("SalirSistema");
    } else {
      this.setFullLoading(true, "Obteniendo información, espere...");

      var resp = await obtenerJsonGET("carrera/obtener_periodos", null);

      if (resp.error == 0) {
        this.periodos = resp.data;
        if (sessionStorage.getItem("periodo")) {
          this.periodo2 = sessionStorage.getItem("periodo");
        } else {
          this.periodo2 = resp.data[0].cod;
          sessionStorage.setItem("periodo", this.periodo2);
        }
      } else {
        this.validarMensaje(resp);
      }
      this.setFullLoading(false, "Obteniendo información, espere...");
    }
  },
  methods: {
    verDetalleServicio: function () {
      this.$router.push("/listaCarrerasXDia/" + this.periodo);
    },
    cambiarPeriodo: async function (val) {
      this.setFullLoading(true, "Obteniendo información, espere...");
      if (typeof val == "object" && val != null) {
        this.periodo = val.cod;
      } else if (val != null) {
        this.periodo = val;
      } else {
        this.periodo = "0";
      }
      if (this.periodo) {
        sessionStorage.setItem("periodo", this.periodo);
        var SendData = {
          periodo: this.periodo,
        };
        var resp = await obtenerJsonGET("carrera/totales_periodos", SendData);
        if (resp.error == 0) {
          this.servicios = resp.data.serv;
          this.combustibles = resp.data.combustible;
          this.gastos = resp.data.gasto;
          this.tags = resp.data.tag;
          this.Totales = resp.data.totales;

        } else {
          this.validarMensaje(resp);
        }
      } else {
        this.servicios = [];
        this.Totales = {};
      }
      this.setFullLoading(false, "Obteniendo información, espere...");
    },
    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
    setFullLoading: async function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
    opcionRapida: async function (paso) {
      alert("crea uno nuevo");
    },
  },
};
</script>