<template>
    <v-card border="opacity-50 sm" class="mx-auto" rounded="xl" variant="text">
      <v-row class="ma-1">
        <v-col cols="12" class="border-col">
          <div class="text-h6 text-katal-title font-weight-bold border-col">
            Tag {{ patente }}
          </div>
          <div
            class="text-caption text-sm-h6 text-blue-accent-1 font-weight-bold border-col"
          >
            Cantidad de tag: {{ cantidadCargas }}
          </div>
        </v-col>
        <v-col cols="12" class="border-col">
          <div
            class="text-h6 text-sm-h5 text-red-accent-1 font-weight-black border-col"
          >
            Total: {{ formatoMonedaChile(valor) }}
          </div>
        </v-col>
      </v-row>
    </v-card>
  </template>
  
  <script>
  import {formatoMonedaChile} from '@/helpers/funcionesEstandard';
  import buttonSubmitform from '@/components/form/buttonSubmitform.vue';
  
  export default {
    data: () => ({
      formatoMonedaChile: formatoMonedaChile,
      
    }),
    props:{
      periodo: Number,
      detalle: Number,
      valor: Number,
      cantidadCargas: Number,
      patente: String,
    },
    components:{
      buttonSubmitform
    },
    watch: {
    },
    methods:{
      verDetalleServicio: function(){
        this.$router.push("/listaCarrerasXDia/"+this.periodo);
      }
    }
  };
  </script>
  
  <style>
  </style>