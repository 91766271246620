<template>
  <v-row>
    <v-col
      cols="12"
      sm="12"
      lg="3"
      class="gradient-background rounded-be-circle text-wite"
    >
      <opcionesMenuRapido :user="user" @SalirSistema="SalirSistema"></opcionesMenuRapido>
    </v-col>
    <v-col cols="12" sm="12" lg="9" align="center" justify="center">
      <v-row class="mt-10 ml-5 mr-5 mb-5">
        <v-col cols="12">
          <calendario :periodo="periodo" :carreras="carreras" :combustible="combustible" :gastos="gastos" :tag="tag"></calendario>
        </v-col>
        <v-col cols="12">
          <buttonSubmitformVue
            @click="volver"
            labelSet="Volver"
          ></buttonSubmitformVue>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { useDate } from "vuetify";
import { VCalendar } from "vuetify/labs/VCalendar";
import { es } from "vuetify/locale";
import { obtenerJsonGET } from "@/helpers/funcionesEstandard";
import opcionesMenuRapido from "@/components/Usuario/principal/opcionesMenuRapido.vue";
import combobox from "@/components/form/combobox.vue";
import calendario from "@/components/calendario/calendario.vue";
import buttonSubmitformVue from "@/components/form/buttonSubmitform.vue";

export default {
  data: () => ({
    periodo: null,
    types: [
      { desc: "x mes", val: "month" },
      { desc: "x semana", val: "week" },
      { desc: "x día", val: "day" },
    ],
    carreras: new Array(),
    gastos: new Array(),
    gat: new Array(),
  }),
  mounted: async function () {
    const adapter = useDate();
    if (!this.user) {
      this.$emit("SalirSistema");
    } else if (!this.user.token) {
      this.$emit("SalirSistema");
    } else {
      this.setFullLoading(true, "Obteniendo información, espere...");
      if (this.$route.params.periodo) {
        this.periodo = this.$route.params.periodo;
        var SendData = {
          periodo: this.periodo,
        };
        var resp = await obtenerJsonGET("carrera/carreras_caledario", SendData);
        if (resp.error == 0) {
          if (resp.data.carreras.length > 0) {
            this.carreras = resp.data.carreras;
            this.combustible = resp.data.combustible;
            this.gastos = resp.data.gasto;
            this.tag = resp.data.tag;
          }
        } else {
          this.validarMensaje(resp);
        }
      } else {
        this.$router.go(-1);
      }
      this.setFullLoading(false, "Obteniendo información, espere...");
    }
  },
  props: {
    user: Object,
  },
  watch: {},
  components: {
    opcionesMenuRapido,
    VCalendar,
    combobox,
    calendario,
    buttonSubmitformVue,
  },
  methods: {
    volver: function () {
      this.$router.go(-1);
    },
    setFullLoading: async function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
    SalirSistema: function(){
      this.$emit("SalirSistema");
    }
  },
};
</script>

<style>
</style>