<template>
  <v-form ref="formNew" v-model="validForm" @submit.prevent="submitNew">
    <v-row align="center" justify="center" class="mt-6">
        <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formcombobox
          labelSet="Vehiculo"
          :itemsSet="Vehiculos"
          :autofocusSet="true"
          MensajeAyudaSet="Seleccione el servicio"
          :requeridoSet="true"
          :valorSet="form.Vehiculo"
          ref="Vehiculo"
        ></formcombobox>
      </v-col>
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formarchivo
          labelSet="Imágen Comprobante"
          MensajeAyudaSet="Máximo 1 fotos de 5MB c/u"
          acceptSet="image/*"
          iconoSet="mdi-camera"
          multipleSet="true"
          :requeridoSet="true"
          textoAlternativoSet="Máximo 1 fotos de 5MB c/u"
          :validatingSet="validating"
          :minFilesSet="1"
          :maxFilesSet="1"
          :autofocusSet="false"
          ref="imagenVale"
        ></formarchivo>
      </v-col>
      
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formtext
          labelSet="Número de comprobante"
          iconoSet="mdi-car-back"
          :maxLengthSet="9"
          :minLengthSet="3"
          MensajeAyudaSet="Ingrese el número de comprobante del gasto"
          :requeridoSet="true"
          textoAlternativoSet="3 a 9 caracteres"
          :validatingSet="validating"
          @SetValor="SetNumeroVale"
          :valorSet="form.numeroVale"
          ref="numeroVale"
        ></formtext>
      </v-col>
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formfechahora
          :autofocusSet="false"
          labelSet="Fecha/Hora"
          :maxFecSet="MaxFec"
          :minFecSet="MinFec"
          :requeridoSet="true"
          :valorSet="form.fechahora"
          ref="fechahora"
        ></formfechahora>
      </v-col>
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formnumero
          :autofocusSet="false"
          labelSet="Valor"
          :requeridoSet="true"
          :valorMaximoSet="500000"
          :valorMinimoSet="1000"
          MensajeAyudaSet="Ingrese el valor total de la carrera"
          textoAlternativoSet="de 1000 a 500000"
          preIconoSet="mdi-currency-usd"
          :valorSet="form.valor"
          ref="valor"
        ></formnumero>
      </v-col>
      <v-col
        :cols="12"
        :xl="widthCol.lg"
        :lg="widthCol.md"
        :md="widthCol.sm"
        :sm="widthCol.xs"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formcombobox
          labelSet="Clasificación del Gasto"
          :itemsSet="TiposDeGastos"
          MensajeAyudaSet="Seleccione o escriba la clasificación del gasto"
          :requeridoSet="true"
          :valorSet="form.tipodegasto"
          ref="tipodegasto"
        ></formcombobox>
      </v-col>
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formarchivo
          labelSet="Otras Imagenes de Respaldo"
          MensajeAyudaSet="Máximo 2 fotos de 5MB c/u"
          acceptSet="image/*"
          iconoSet="mdi-camera"
          multipleSet="true"
          :requeridoSet="true"
          textoAlternativoSet="Máximo 2 fotos de 5MB c/u"
          :validatingSet="validating"
          :minFilesSet="1"
          :maxFilesSet="2"
          :autofocusSet="false"
          ref="otrasFotos"
        ></formarchivo>
      </v-col>












      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formCheckBox
          :inlineSet="true"
          :opciones="sino"
          labelSet="'¿uso dinero de carrera en efectivo?"
          :valorSet="form.efectivo"
          ref="efectivo"
        ></formCheckBox>
      </v-col>
      <v-col cols="12" class="border-col">
        <formtextoGrande
          :autofocusSet="false"
          labelSet="Descripción del gasto"
          :requeridoSet="true"
          :minLengthSet="0"
          :maxLengthSet="200"
          MensajeAyudaSet="Describa detalladamente de que trata el gasto."
          textoAlternativoSet="de 0 a 50"
          preIconoSet="mdi-caps-lock"
          :valorSet="form.descripcion"
          ref="descripcion"
        ></formtextoGrande>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <formbtnsend
              labelSet="Guardar"
              :validating="validating"
            ></formbtnsend>
          </v-col>
          <v-col cols="6">
            <formbtncancel
              labelSet="Volver"
              @accion="volver"
              :validating="validating"
            ></formbtncancel>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  obtenerJsonGET,
  enviarJsonPOST,
  formatoFechaHoraVue,
} from "@/helpers/funcionesEstandard";
import formarchivo from "@/components/form/archivos.vue";
import formtext from "@/components/form/textos.vue";
import formbtnsend from "@/components/form/buttonSubmitform.vue";
import formbtncancel from "@/components/form/buttonCancelform.vue";
import formtextoGrande from "@/components/form/textoGrande.vue";
import formcombobox from "@/components/form/combobox.vue";
import formmultiplocombobox from "@/components/form/multiplecombobox.vue";
import formfechahora from "@/components/form/fechaHora.vue";
import formnumero from "@/components/form/numero.vue";
import formCheckBox from "@/components/form/checkBox.vue";


export default {
  data: () => ({
    widthCol: {
      xl: 2,
      lg: 3,
      md: 4,
      sm: 6,
      xs: 12,
    },
    validating: null,
    validForm: null,
    form: {
        Vehiculo: null,
        numeroVale: null,
        fechahora: null,
        valor: null,
        tipodegasto: null,
        efectivo: 2,
        descripcion: null,
    },
    MaxFec: null,
    MinFec: null,
    sino: [
      { label: "Si", valor: 1 },
      { label: "No", valor: 2 },
    ],
    Vehiculos: [],
    TiposDeGastos: [],
  }),
  components: {
    formarchivo,
    formtext,
    formbtnsend,
    formbtncancel,formtextoGrande,formcombobox,formmultiplocombobox,formfechahora,formnumero,formCheckBox,
  },
  mounted: function(){
    
    this.validating = true;
    this.setFullLoading(true, "Cargando información...");
    var fec = new Date();
    this.MaxFec = formatoFechaHoraVue(fec);
    var fec2 = new Date();
    var numberOfMlSeconds = fec2.getTime();
    var addMlSeconds = 30 * 24 * 60 * 60000;
    var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
    this.MinFec = formatoFechaHoraVue(newDateObj);
    this.validating = false;
    this.setFullLoading(false, null);
    this.obtenerVehiculos();
    this.obtenerClasificaciones();
  },
  methods: {
    submitNew: async function () {
      const { valid } = await this.$refs.formNew.validate();
      if (valid) {
        this.validating = true;
        this.setFullLoading(true, "Guardando gasto...");
        if (this.$refs.otrasFotos.valor) {
          for (var x = 0; x < this.$refs.otrasFotos.valor.length; x++) {
            let size = this.$refs.otrasFotos.valor[x].size / 1024 / 1024;
            let date = this.$refs.otrasFotos.valor[x].lastModifiedDate;
            if (size > 5) {
              let obj = {
                error: 1,
                message:
                  "Los archivos adjuntos no pueden superar 5MB, por favor corregir.",
              };
              this.validating = false;
              this.setFullLoading(
                false,
                "Guardando gasto " + this.msgSend + "..."
              );
              this.validarMensaje(obj);
              return false;
            }
          }
        }
        let fd = new FormData();
        fd.append("Vehiculo", this.$refs.Vehiculo.valor.cod);

        fd.append("fotoVale", this.$refs.imagenVale.valor[0]);
        if (this.$refs.otrasFotos.valor) {
          for (let x = 0; x < this.$refs.otrasFotos.valor.length; x++) {
            fd.append("fotos[]", this.$refs.otrasFotos.valor[x]);
          }
        }
        fd.append("numeroVale", this.$refs.numeroVale.valor);
        fd.append("fechahora", this.$refs.fechahora.valor);
        fd.append("valor", this.$refs.valor.valor);
        fd.append("tipodegasto", this.$refs.tipodegasto.valor);
        fd.append("efectivo", this.$refs.efectivo.valor);
        fd.append("descripcion", this.$refs.descripcion.valor);

        var json = await enviarJsonPOST("gasto/nuevo", fd);
        if (json.error == 0) {
          this.$router.go(-1);
          this.validarMensaje(json);
        } else {
          this.validarMensaje(json);
        }
        this.validating = false;
        this.setFullLoading(false, null);
      }
    },
    obtenerVehiculos: async function () {
      this.setFullLoading(true, "Buscando vehículos...");
      this.buttonLoading = true;
      let fd = new FormData();
      var json = await obtenerJsonGET(
        "vehiculo/obtener_listado_vehiculo_usuario",
        null
      );
      if (json.error == 0) {
        if (json.data) {
          this.Vehiculos = json.data;
        }
      } else {
        this.validarMensaje(json);
      }
      this.setFullLoading(false, null);
    },
    obtenerClasificaciones: async function () {
      this.setFullLoading(true, "Buscando clasificaciones...");
      this.buttonLoading = true;
      let fd = new FormData();
      var json = await obtenerJsonGET(
        "clasificacionGasto/listado_combobox",
        null
      );
      if (json.error == 0) {
        if (json.data) {
          this.TiposDeGastos = json.data;
          if(json.message){
            this.validarMensaje(json);    
          }
        }
      } else {
        this.validarMensaje(json);
      }
      this.setFullLoading(false, null);
    },
    volver: function () {
      this.$emit("volver");
    },
    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
    setFullLoading: async function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
  },
};
</script>

<style>
</style>