<template>
  <div>
    <v-img
      class="cursor-pointer"
      :width="100"
      aspect-ratio="16/9"
      cover
      :src="img"
      @click="opcionRapida(codigo)"
    ></v-img>
  </div>
</template>

<script>
export default {
  props: {
    codigo: String,
    user: String,
    img: String,
  },
  methods: {
    opcionRapida: function (codigo) {
      this.$emit("opcionRapida", codigo);
    },
  },
};
</script>

<style>
</style>