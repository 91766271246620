<template>
  <div class="bg-red-lighten-4 text-start pl-2 text-katal-carrera-calendario rounded-lg elevation-6" max-width="100" @click="mostrarDetalle">
    <v-icon>mdi-gas-station-outline</v-icon>
    C:{{ combustible.cantidad }}<br>
    V:{{ formatoMonedaChile(-combustible.valor) }}
  </div>
</template>

<script>
import { formatoMonedaChile } from "@/helpers/funcionesEstandard";

export default {
  data: () => ({
    clase: null,
    formatoMonedaChile: formatoMonedaChile,
  }),
  props: {
    combustible: Object,
  },
  mounted: function () {
    this.clase = "rounded-lg cursor-pointer text-caption elevation-9 mt-2 " + this.combustible.color;
  },
  methods:{ 
    mostrarDetalle: async function(){
      
    }
  }
};
</script>

<style>
</style>