<template>
  <v-form ref="formNew" v-model="validForm"     >
    <v-row align="center" justify="center" class="mt-6">
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formcombobox
          labelSet="Vehiculo"
          :itemsSet="Vehiculos"
          :autofocusSet="true"
          MensajeAyudaSet="Seleccione el conductor"
          :requeridoSet="true"
          :valorSet="form.Vehiculo"
          ref="Vehiculo"
          :validatingSet="validating"
        ></formcombobox>
      </v-col>
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formcombobox
          labelSet="Período"
          :itemsSet="Periodos"
          :autofocusSet="true"
          MensajeAyudaSet="Seleccione el período"
          :requeridoSet="true"
          :valorSet="form.periodo"
          ref="Vehiculo"
          :validatingSet="validating"
        ></formcombobox>
      </v-col>
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formarchivo
          labelSet="Imágen Comprobante"
          MensajeAyudaSet="Máximo 1 fotos de 5MB c/u"
          acceptSet="image/*"
          iconoSet="mdi-camera"
          multipleSet="true"
          :requeridoSet="true"
          textoAlternativoSet="Máximo 1 fotos de 5MB c/u"
          :validatingSet="validating"
          :minFilesSet="1"
          :maxFilesSet="1"
          :autofocusSet="false"
          ref="imagenVale"
        ></formarchivo>
      </v-col>
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formtext
          labelSet="Número de comprobante"
          iconoSet="mdi-car-back"
          :maxLengthSet="9"
          :minLengthSet="3"
          MensajeAyudaSet="Ingrese el número de comprobante del pago"
          :requeridoSet="true"
          textoAlternativoSet="3 a 9 caracteres"
          :validatingSet="validating"
          @SetValor="SetNumeroVale"
          :valorSet="form.numeroVale"
          ref="numeroVale"
        ></formtext>
      </v-col>
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formfechahora
          :autofocusSet="false"
          labelSet="Fecha/Hora"
          :maxFecSet="MaxFec"
          :minFecSet="MinFec"
          :requeridoSet="true"
          :valorSet="form.fechahora"
          ref="fechahora"
        ></formfechahora>
      </v-col>
      
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formnumero
          :autofocusSet="false"
          labelSet="Valor"
          :requeridoSet="true"
          :valorMaximoSet="500000"
          :valorMinimoSet="1000"
          MensajeAyudaSet="Ingrese el valor total del pago"
          textoAlternativoSet="de 1000 a 500000"
          preIconoSet="mdi-currency-usd"
          :valorSet="form.valor"
          ref="valor"
        ></formnumero>
      </v-col>
      
      <v-col cols="12" class="border-col">
        <formtextoGrande
          :autofocusSet="false"
          labelSet="Descripción del pago"
          :requeridoSet="true"
          :minLengthSet="0"
          :maxLengthSet="200"
          MensajeAyudaSet="Describa detalladamente de que trata el pago."
          textoAlternativoSet="de 0 a 50"
          preIconoSet="mdi-caps-lock"
          :valorSet="form.descripcion"
          ref="descripcion"
        ></formtextoGrande>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <formbtnsend
              labelSet="Guardar"
              :validating="validating"
            ></formbtnsend>
          </v-col>
          <v-col cols="6">
            <formbtncancel
              labelSet="Volver"
              @accion="volver"
              :validating="validating"
            ></formbtncancel>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import formcombobox from "@/components/form/combobox.vue";
import formarchivo from "@/components/form/archivos.vue";
import formtext from "@/components/form/textos.vue";
import formfechahora from "@/components/form/fechaHora.vue";
import formnumero from "@/components/form/numero.vue";
import formtextoGrande from "@/components/form/textoGrande.vue";
import formbtnsend from "@/components/form/buttonSubmitform.vue";
import formbtncancel from "@/components/form/buttonCancelform.vue";

export default {
  data: () => ({
    Vehiculos: [],
    Periodos: [],
    validating: false,
    form: {
        Vehiculo: null,
        numeroVale: null,
        fechahora: null,
        valor: null,
        periodo: null,
        descripcion: null,
    },
    widthCol: {
      xl: 2,
      lg: 3,
      md: 4,
      sm: 6,
      xs: 12,
    },
    MaxFec: null,
    MinFec: null,
  }),
  components: {
    formcombobox,
    formarchivo,
    formtext,
    formfechahora,
    formnumero,
    formtextoGrande,
    formbtnsend,
    formbtncancel,
  },
  methods: {
    submitNew: async function () {
    },
    volver: function () {
      this.$emit("volver");
    },
    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
    setFullLoading: async function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
  },
};
</script>

<style>
</style>