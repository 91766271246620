<template>
  <v-app-bar color="cyan-darken-1">
    <template v-slot:prepend v-if="user.usuarioId">
      <v-app-bar-nav-icon
        variant="text"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </template>

    <v-app-bar-title>
      <img src="/img/LogoSolo.png" width="40px" @click="raiz()" />
      Katal
    </v-app-bar-title>
    <modulosVue class="d-md-block d-none"></modulosVue>
    <beneficiosVue class="d-md-block d-none"></beneficiosVue>
    <FAQSVue class="d-md-block d-none"></FAQSVue>
    <v-spacer class="d-md-block d-none"></v-spacer>
    <loginVue :user="user" class="d-md-block d-none" @setUsuario="setUsuario"></loginVue>
    <registroConductorVue 
            :user="user"
            :usuarioId="usuarioId"
            @validarMensaje="validarMensaje"
            @setUsuario="setUsuario"
            @setFullLoading="setFullLoading"
            @SalirSistema="SalirSistema"></registroConductorVue>
    <v-btn icon="mdi-dots-vertical" variant="text" class="d-block d-md-none" @click="drawer2=!drawer2"></v-btn>
  </v-app-bar>
  <v-navigation-drawer
        v-model="drawer2"
        location="right"
        class="d-block d-md-none gradient-background_menu_superior"
        temporary
      >
      <modulosVue></modulosVue>
      <beneficiosVue></beneficiosVue>
    <FAQSVue></FAQSVue>
    <v-spacer></v-spacer>
    <loginVue :user="user" @setUsuario="setUsuario"></loginVue>
      </v-navigation-drawer>
  <v-navigation-drawer
    v-model="drawer"
    temporary
    class="gradient-background_menu_superior"
  >
    <v-list-item
      prepend-avatar="/img/LogoSolo.png"
      :title="user.name"
      nav
      @click="menuPrincipal()"
    >
      <template v-slot:append>
        <v-btn
          icon="mdi-chevron-left"
          variant="text"
          @click.stop="drawer = !drawer"
        ></v-btn>
      </template>
    </v-list-item>

    <v-divider></v-divider>

    <v-list density="compact" nav>
      <v-list-item
        :prepend-avatar="op.img"
        :title="op.nombre"
        :value="op.codigo"
        v-for="op in user.options"
        :key="op"
        @click="activarOpcionMenu(op.codigo)"
      ></v-list-item>
      <v-list-item
        prepend-avatar="/img/salir_katal.png"
        title="Salir"
        value="exit"
        @click="SalirSistema"
      ></v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import FAQSVue from './FAQS.vue';
import modulosVue from './modulos.vue';
import loginVue from './login.vue';
import beneficiosVue from './beneficios.vue';
import registroConductorVue from './registroConductor.vue';

export default {
  data: () => ({
    drawer: true,
    drawer2: false,
    rail: true,
    optionPrincipal: [{ name: "Módulos", action: "pendiente" }],

    fav: true,
    menu: false,
    message: false,
    hints: true,
  }),
  props: {
    user: Object,
  },
  components: {
    modulosVue,
    FAQSVue,
    loginVue,
    beneficiosVue,
    registroConductorVue,
  },
  mounted: function () {},
  methods: {
    raiz: function(){
      this.$router.push("/");
    },
    menuPrincipal: function () {
      this.drawer = !this.drawer;
      this.$router.push("/menuPrincipal");
    },
    faqs: function () {
      this.$router.push("/faqs");
    },
    activarOpcionMenu: function (codigo) {
      this.$router.push("/New/" + codigo);
    },
    setUsuario: function(usr){
      this.$emit("setUsuario",usr);
    },
    setFullLoading: async function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>

<style>
.gradient-background_menu_superior {
  background: linear-gradient(to right, white, #00acc1);
  justify-content: center;
  align-items: top;
}
</style>