<template>
  <v-row class="ml-6 mr-2 mb-8 mt-5">
    <v-col cols="12">
      <v-row>
        <v-col cols="3" lg="12">
          <v-img
            src="/img/LogoSolo.png"
            width="100%"
            class="rounded-xl"
          ></v-img>
        </v-col>
        <v-col cols="9" lg="12">
          <div class="text-center text-katal-subtitle">
            ¿Que hacemos {{ user.name }}?
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="3" sm="6" v-for="boton in botones" :key="boton" >
      <botonAccionRapida
        :img="boton.img"
        :codigo="boton.codigo"
        @opcionRapida="opcionRapida"
      ></botonAccionRapida>
    </v-col>
    <v-col cols="3" sm="6">
      <botonAccionRapida
        img="/img/salir_katal.png"
        :codigo="salir"
        @opcionRapida="salir"
      ></botonAccionRapida>
    </v-col>
  </v-row>
</template>

<script>
import botonAccionRapida from "@/components/Usuario/principal/botonAccion.vue";

export default {
  data: () => ({
    codigo: null,
    botones: [],
  }),
  props: {
    user: String,
  },
  components: {
    botonAccionRapida,
  },
  mounted: function(){
    this.botones = this.user.options;
    
  },
  methods: {
    salir() {
      this.$emit("SalirSistema");
    },
    opcionRapida: function (codigo) {
      this.$router.push("/New/" + codigo);
    },
  },
};
</script>