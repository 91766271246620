<template>
  <div class="text-md-center pa-4">
    <v-dialog v-model="dialog" transition="dialog-top-transition" fullscreen>
      <template v-slot:activator="{ props: activatorProps }">
        <span v-if="!user.usuarioId">
          <v-btn
            class="bg-blue-grey-darken-1"
            prepend-icon="mdi-emoticon-outline"
            size="small"
            text="Pruebalo Gratis"
            v-bind="activatorProps"
          ></v-btn>
        </span>
      </template>

      <v-card>
        <v-toolbar class="gradient-background_menu_superior">
          <v-btn icon="mdi-close" @click="dialog = false"></v-btn>

          <v-toolbar-title>
            <span class="text-h5 display-1 font-weight-bold">Registro Nuevo Conductor</span>
          </v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container class="bg-blue-lighten-5 mt-2">
          <v-card-text class="mt-12 ml-2 mr-2 border-s-lg">
            <v-form
              ref="formNew"
              v-model="validForm"
              @submit.prevent="submitNew"
            >
              <v-row justify="center">
                <v-col cols="6" sm="2" class="align-self-center">
                  <v-img
                    src="/img/LogoSolo.png"
                    width="100%"
                    class="rounded-xl"
                  ></v-img>
                </v-col>
                <v-col cols="12" sm="10">
                  <v-row>
                    <v-col cols="12" class="text-center text-katal-title"
                      >Nuevo registro</v-col
                    >

                    <v-col cols="12" class="text-center text-katal-title3">
                      Registrarse en nuestro sistema te permitirá gestionar tu
                      flota de manera más eficiente, ahorrar costos operativos y
                      brindar un mejor servicio a tus clientes. <br />¡Únete y
                      lleva tu negocio al siguiente nivel!</v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="12" class="mt-8">
                  <v-row class="border border-opacity-25 rounded elevation-25">
                    <v-col cols="12" class="text-katal-title3">
                      Antecedentes personales
                    </v-col>
                    <v-col cols="12" sm="6" class="border-col">
                      <formtext
                        iconoSet="mdi-account-outline"
                        labelSet="Nombres"
                        :requeridoSet="true"
                        MensajeAyudaSet="Ingrese su nombre"
                        :validatingSet="validating"
                        autofocus-set="true"
                        ref="nombreUsuario"
                      ></formtext>
                    </v-col>

                    <v-col cols="12" sm="3" class="border-col">
                      <formtext
                        iconoSet="mdi-account-box-outline"
                        labelSet="Apellido paterno"
                        :requeridoSet="true"
                        MensajeAyudaSet="Ingresu su apellido paterno"
                        :validatingSet="validating"
                        ref="appUsuario"
                      ></formtext>
                    </v-col>
                    <v-col cols="12" sm="3" class="border-col">
                      <formtext
                        iconoSet="mdi-account-box-outline"
                        labelSet="Apellido materno"
                        :requeridoSet="true"
                        MensajeAyudaSet="Ingresu su apellido materno"
                        :validatingSet="validating"
                        ref="apmUsuario"
                      ></formtext>
                    </v-col>
                    <v-col cols="12" sm="4" class="border-col">
                      <formrut
                        labelSet="RUT"
                        :requeridoSet="true"
                        :validatingSet="validating"
                        ref="rutUsuario"
                      ></formrut>
                    </v-col>
                    <v-col cols="12" sm="8" class="border-col">
                      <formemail
                        labelSet="Email"
                        :requeridoSet="true"
                        :validatingSet="validating"
                        ref="emailUsuario"
                      ></formemail>
                    </v-col>
                  </v-row>
                  <v-row
                    class="border border-opacity-25 rounded elevation-25 mt-4"
                  >
                    <v-col
                      cols="12"
                      sm="12"
                      class="border-col text-katal-title3"
                    >
                      Servicio
                    </v-col>
                    <v-col cols="12" sm="12" class="border-col">
                      <checkTipoUsuario
                        :opciones="tipoUsuario"
                        valorSet="1"
                        class="border-col"
                        :inlineSet="true"
                        @SetValor="asignaTipoUSuario"
                        ref="tipoUsuario"
                      ></checkTipoUsuario>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                      class="border-col"
                      v-if="tipoUsuarioVal == '1'"
                    >
                      <formrut
                        labelSet="RUT Empresa"
                        :requeridoSet="true"
                        :validatingSet="validating"
                        ref="rutEmpresa"
                        @perderFoco="buscarEmpresaRut"
                      ></formrut>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="9"
                      class="border-col"
                      v-if="tipoUsuarioVal == '1'"
                    >
                      <formtext
                        iconoSet="mdi-bank-outline"
                        labelSet="Nombre Empresa"
                        :requeridoSet="true"
                        MensajeAyudaSet="Ingresu el nombre de la empresa"
                        :validatingSet="validating"
                        :readonly-set="rutEmpresaREadOnly"
                        :valorSet="nombreEmpresa"
                        ref="nombreEmpresa"
                      ></formtext>
                    </v-col>
                    <v-col cols="12" sm="2" class="border-col">
                      <formtext
                        iconoSet="mdi-car-hatchback"
                        labelSet="Patente"
                        :requeridoSet="true"
                        MensajeAyudaSet="Ingrese la patente del vehículo con el que trabaja"
                        :validatingSet="validating"
                        @perderFoco="buscarVehiculoPatente"
                        ref="patente"
                      ></formtext>
                    </v-col>
                    <v-col cols="12" sm="4" class="border-col">
                      <formtext
                        iconoSet="mdi-car-hatchback"
                        labelSet="Marca"
                        :valorSet="marca"
                        :requeridoSet="true"
                        MensajeAyudaSet="Ingrese la marca del vehículo con el que trabaja"
                        :validatingSet="validating"
                        :readonlySet="vehiculoReadOnly"
                        ref="marca"
                      ></formtext>
                    </v-col>
                    <v-col cols="12" sm="4" class="border-col">
                      <formtext
                        iconoSet="mdi-car-hatchback"
                        labelSet="Modelo"
                        :valorSet="modelo"
                        :requeridoSet="true"
                        MensajeAyudaSet="Ingrese el modelo del vehículo con el que trabaja"
                        :validatingSet="validating"
                        :readonlySet="vehiculoReadOnly"
                        ref="modelo"
                      ></formtext>
                    </v-col>
                    <v-col cols="12" sm="2" class="border-col">
                      <formnumero
                        iconoSet="mdi-car-hatchback"
                        labelSet="Año"
                        :valorSet="agno"
                        :requeridoSet="true"
                        :valorMinimoSet="1980"
                        :valorMaximoSet="2025"
                        MensajeAyudaSet="Ingrese el año del vehículo con el que trabaja"
                        :validatingSet="validating"
                        :readonlySet="vehiculoReadOnly"
                        ref="agno"
                      ></formnumero>
                    </v-col>
                    <v-col cols="6" sm="2" class="border-col">
                      <formnumero
                        labelSet="Inicio de Corte"
                        MensajeAyudaSet="Dia del mes que inicia cada período (1-28)"
                        textoAlternativoSet="1"
                        preIconoSet="mdi-calendar-arrow-right"
                        :validatingSet="validating"
                        :requeridoSet="true"
                        :valorMaximoSet="28"
                        :valorMinimoSet="1"
                        ref="inicioCorte"
                      ></formnumero>
                    </v-col>
                    <v-col cols="6" sm="2" class="border-col">
                      <formnumero
                        labelSet="Inicio Turno"
                        MensajeAyudaSet="Hora en que inicia el turno (0-23)"
                        textoAlternativoSet="1"
                        preIconoSet="mdi-account-clock-outline"
                        :validatingSet="validating"
                        :requeridoSet="true"
                        :valorMaximoSet="23"
                        :valorMinimoSet="1"
                        ref="inicioTurno"
                      ></formnumero>
                    </v-col>
                    <v-col cols="6" sm="2" class="border-col">
                      <formnumero
                        labelSet="Término Turno"
                        MensajeAyudaSet="Hora en que termina el turno (0-23)"
                        textoAlternativoSet="1"
                        preIconoSet="mdi-account-clock-outline"
                        :validatingSet="validating"
                        :requeridoSet="true"
                        :valorMaximoSet="23"
                        :valorMinimoSet="1"
                        ref="terminoTurno"
                      ></formnumero>
                    </v-col>
                    <v-col cols="12" sm="6" class="border-col">
                      <formmultiplecombobox
                        iconoSet="mdi-car-hatchback"
                        labelSet="Nombre Servicio"
                        :requeridoSet="true"
                        MensajeAyudaSet="Escriba o seleccione a quien le presta servicio."
                        :validatingSet="validating"
                        ref="Servicios"
                        :itemsSet="listaServicios"
                      ></formmultiplecombobox>
                    </v-col>
                    <v-col cols="12" class="text-caption">
                      <div>
                        Ventajas de Registrarse en Nuestro Sistema de Gestión de
                        Flotas.
                      </div>
                      <div class="border-thin pa-3 rounded-lg mt-4">
                        <span class="font-weight-medium"
                          >1. Eficiencia Operativa:</span
                        >
                        <span class="text-medium-emphasis"
                          >Optimiza la asignación de carreras y reduce tiempos
                          de espera gracias a la tecnología GPS y algoritmos
                          avanzados de distribución.</span
                        >
                        <br /><span class="font-weight-medium"
                          >2. Control Financiero:</span
                        >
                        <span class="text-medium-emphasis"
                          >Lleva un registro detallado de ingresos y gastos, lo
                          que facilita la gestión financiera y la maximización
                          de utilidades.</span
                        >
                        <br /><span class="font-weight-medium"
                          >3. Mantenimiento Preventivo:</span
                        >
                        <span class="text-medium-emphasis"
                          >Programa y monitorea el mantenimiento de los
                          vehículos para evitar averías inesperadas y prolongar
                          la vida útil de la flota.</span
                        >
                        <br /><span class="font-weight-medium"
                          >4. Transparencia y Confianza:</span
                        >
                        <span class="text-medium-emphasis"
                          >Mejora la transparencia en la distribución de
                          utilidades y pagos a conductores, generando mayor
                          confianza en el equipo.</span
                        >
                        <br /><span class="font-weight-medium"
                          >5. Atención al Cliente:</span
                        >
                        <span class="text-medium-emphasis"
                          >Ofrece a tus clientes una plataforma de reservas
                          fácil de usar, seguimiento en tiempo real y
                          facturación automática, mejorando su experiencia y
                          satisfacción.</span
                        >
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <formbtnsend
                        labelSet="Registrar"
                        :validating="validating"
                      ></formbtnsend>
                    </v-col>
                    <v-col cols="6">
                      <formbtncancel
                        labelSet="Volver"
                        @accion="volver"
                        :validating="validating"
                      ></formbtncancel>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
    
<script>
import { obtenerJsonGET, enviarJsonPOST } from "@/helpers/funcionesEstandard";

import formemail from "@/components/form/email.vue";
import formrut from "@/components/form/rut.vue";
import formtext from "@/components/form/textos.vue";
import formbtnsend from "@/components/form/buttonSubmitform.vue";
import formbtncancel from "@/components/form/buttonCancelform.vue";
import checkTipoUsuario from "@/components/form/checkBox.vue";
import formnumero from "@/components/form/numero.vue";
import formmultiplecombobox from "@/components/form/multiplecombobox.vue";
export default {
  data() {
    return {
      dialog: false,
      tipoUsuarioVal: 1,
      nombreEmpresa: null,
      rutEmpresaREadOnly: false,
      listaServicios: [],
      marca: null,
      modelo: null,
      agno: null,
      vehiculoReadOnly: null,
      tipoUsuario: [
        {
          label: "Conductor en una empresa",
          valor: 1,
        },
        {
          label: "Conductor independiente",
          valor: 2,
        },
      ],
      validating: false,
    };
  },
  mounted: async function () {
    this.buscarServicios();
  },
  props: {
    user: Object,
  },
  
  components: {
    formemail,
    formrut,
    formtext,
    formbtnsend,
    formbtncancel,
    checkTipoUsuario,
    formnumero,
    formmultiplecombobox,
  },
  methods: {
    buscarServicios: async function () {
      let rut = null;
      this.listaServicios = [];
      if(arguments.length>0){
        rut = arguments[0];
      }
      this.setFullLoading(true, "Buscando servicios...");
      this.buttonLoading = true;
      var SendData = null;
      if(rut){
        SendData = {
          rut: rut,
        };
      }
      var json = await obtenerJsonGET(
        "servicio/obtener_servicios_combobox_all",
        SendData
      );
      if (json.error == 0) {
        if (json.data) {
          this.listaServicios = json.data;
        }
      } else {
      }
      this.setFullLoading(false, null);
    },
    /*SeleccionaServicio: function(servicios){
      for (let servicio in servicios){
        this.agregaCodigoServicio = false;
        this.tamanoCodigoServicio = 6;
        this.nuevoCodigoServicio = "Cód serv.";
        if(typeof servicios[servicio] == 'string'){
          this.agregaCodigoServicio = true;
          this.tamanoCodigoServicio = 4;
          this.nuevoCodigoServicio=this.nuevoCodigoServicio+' '+servicios[servicio];
        }
      }
    },*/
    buscarVehiculoPatente: async function (val) {
      if (val) {
        this.setFullLoading(true, "Buscando vehículo...");
        let fd = new FormData();
        fd.append("patente", val);
        var json = await enviarJsonPOST("vehiculo/obtenervehiculo", fd);
        if (json.error == 0) {
          if (json.data.patente) {
            this.marca = json.data.marca;
            this.modelo = json.data.modelo;
            this.agno = json.data.agno;
            this.vehiculoReadOnly = true;
          } else {
            this.marca = null;
            this.modelo = null;
            this.agno = null;
            this.vehiculoReadOnly = false;
          }
        } else {
          this.marca = null;
          this.modelo = null;
          this.agno = null;
          this.vehiculoReadOnly = false;
        }
      }
      this.setFullLoading(false, null);
    },
    buscarEmpresaRut: async function (val) {
      if (val) {
        this.setFullLoading(true, "Buscando empresa...");
        let fd = new FormData();
        fd.append("rutEmpresa", val);
        var json = await enviarJsonPOST("empresa/obtener_rut", fd);
        if (json.error == 0) {
          if (json.data.nombre) {
            this.nombreEmpresa = json.data.nombre;
            this.rutEmpresaREadOnly = true;
          } else {
            this.nombreEmpresa = null;
            this.rutEmpresaREadOnly = false;
          }
        } else {
          this.nombreEmpresa = null;
          this.rutEmpresaREadOnly = false;
        }
        this.$refs.Servicios.valor = null;
      }
      this.buscarServicios(val);
      this.setFullLoading(false, null);
    },
    asignaTipoUSuario: function (val) {
      this.tipoUsuarioVal = val;
    },
    volver: function () {
      this.dialog=false;
    },
    submitNew: async function () {
      const { valid } = await this.$refs.formNew.validate();
      if (valid) {
        this.setFullLoading(true, "Creando registro...");
        this.validating = true;
        let fd = new FormData();
        fd.append("nombreUsuario", this.$refs.nombreUsuario.valor);
        fd.append("appUsuario", this.$refs.appUsuario.valor);
        fd.append("apmUsuario", this.$refs.apmUsuario.valor);
        fd.append("rutUsuario", this.$refs.rutUsuario.valor);
        fd.append("emailUsuario", this.$refs.emailUsuario.valor);
        fd.append("tipoUsuario", this.$refs.tipoUsuario.valor);
        if (this.$refs.tipoUsuario.valor + "" == "1") {
          fd.append("rutEmpresa", this.$refs.rutEmpresa.valor);
          fd.append("nombreEmpresa", this.$refs.nombreEmpresa.valor);
        }
        fd.append("patente", this.$refs.patente.valor);
        fd.append("marca", this.$refs.marca.valor);
        fd.append("modelo", this.$refs.modelo.valor);
        fd.append("agno", this.$refs.agno.valor);
        fd.append("inicioCorte", this.$refs.inicioCorte.valor);
        fd.append("inicioTurno", this.$refs.inicioTurno.valor);
        fd.append("terminoTurno", this.$refs.terminoTurno.valor);
        fd.append("servicios", JSON.stringify(this.$refs.Servicios.valor));

        var json = await enviarJsonPOST("usuario/crear_nueva_cuenta", fd);
        if (json) {
          if (json.error == 0) {
            sessionStorage.setItem("correo", this.$refs.emailUsuario.valor);
            this.$router.push("/ValidaCorreo/");
          } else {
            this.validarMensaje(json);
          }
        }
      }
      this.validating = false;
      this.setFullLoading(false, "Creando registro...");
    },
    setUsuario: function(usr){
      this.$emit("setUsuario",usr);
    },
    setFullLoading: async function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
    SalirSistema: function () {
      this.$emit("SalirSistema");
    },
  },
};
</script>
    
    <style>
</style>