import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/components/Usuario/seguridad/login.vue';
import nuevoRegistro from "@/components/Usuario/seguridad/NuevoRegistro.vue";
import ValidaCorreo from "@/components/Usuario/seguridad/ValidaCorreo.vue";
import BaseForm from "@/components/form/BaseForm.vue";
import listaCarrerasXDia from "@/components/carrera/listaCarrerasXDia.vue";
import verCarrera from "@/components/carrera/verCarrera.vue";
import faqs from "@/components/Usuario/principal/FAQS.vue";


import menuPrincipal from '@/components/Usuario/principal/menuPrincipal.vue';


const routes = [
  {
    path: '/',
    name: 'home',
    component: Login
  },
  {
    path: '/nuevoRegistro',
    name: 'nuevoRegistro',
    component: nuevoRegistro
  },
  {
    path: '/ValidaCorreo',
    name: 'ValidaCorreo',
    component: ValidaCorreo
  },
  {
    path: '/ValidaCorreo/:email',
    name: 'ValidaCorreoE',
    component: ValidaCorreo
  },
  {
    path: '/ValidaCorreo/:email/:codigo',
    name: 'ValidaCorreoEC',
    component: ValidaCorreo
  },
  {
    path: '/CambioClave/:email/:password',
    name: 'cambioclave2',
    component: Login
  },
  {
    path: '/CambioClave/:email/',
    name: 'cambioclave1',
    component: Login
  },
  {
    path: '/New/:form/',
    name: 'nuevoForm2',
    component: BaseForm
  },
  {
    path: '/New/',
    name: 'nuevoForm1',
    component: BaseForm
  },
  {
    path: '/Edit/',
    name: 'editForm1',
    component: BaseForm
  },
  {
    path: '/Edit/:form/:id',
    name: 'editForm2',
    component: BaseForm
  },
  {
    path: '/listaCarrerasXDia/',
    name: 'listaCarrerasXDia',
    component: listaCarrerasXDia
  },
  {
    path: '/listaCarrerasXDia/:periodo',
    name: 'listaCarrerasXDia2',
    component: listaCarrerasXDia
  },
  {
    path: '/FAQS',
    name: 'FAQS',
    component: faqs
  },
  
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/validarcodigo/:email/:codigo',
    name: 'validarcodigo',
    component: Login
  },
  {
    path: '/ingresarusuariovalidado/:email/:clave',
    name: 'ingresarusuariovalidado',
    component: Login
  },
  {
    path: '/menuPrincipal',
    name: 'menuPrincipal',
    component: menuPrincipal
  },/*
  
  {
    path: '/:param1',
    name: 'home1',
    component: Login
  },{
    path: '/:param1/:param2',
    name: 'home2',
    component: Login
  },{
    path: '/:param1/:param2/:param3',
    name: 'home3',
    component: Login
  },{
    path: '/:param1/:param2/:param3/:param4',
    name: 'home4',
    component: Login
  },{
    path: '/:param1/:param2/:param3/:param4/:param5',
    name: 'home5',
    component: Login
  },{
    path: '/:param1/:param2/:param3/:param4/:param5/:param6',
    name: 'home6',
    component: Login
  },{
    path: '/:param1/:param2/:param3/:param4/:param5/:param6/:param7',
    name: 'home7',
    component: Login
  },*/
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
