<template>
  <v-row>
    <v-col cols="4" class="text-caption" align="right" justify="center">
      {{ label }}
    </v-col>
    <v-col cols="8" class="text-caption">
      <v-radio-group
        :inline="inline"
        v-model="valor"
        compact
        :readonly="readonly"
      >
        <v-radio
          v-for="opcion in opciones"
          :key="opcion"
          :label="opcion.label"
          :value="opcion.valor + ''"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    label: null,
    valor: null,
    inline: false,
    readonly: false,
  }),
  props: {
    opciones: Array,
    valorSet: String,
    inlineSet: Boolean,
    labelSet: String,
    readonlySet: Boolean,
  },
  mounted: function () {
    if (this.labelSet) {
      this.label = this.labelSet;
    }
    if (this.valorSet) {
      this.valor = this.valorSet + "";
    }
    if (this.inlineSet) {
      this.inline = this.inlineSet;
    }
    if (this.readonlySet) {
      this.readonly = this.readonlySet;
    }
  },
  watch: {
    valor: function (val) {
      this.$emit("SetValor", val);
    },
    valorSet: function (val) {
      this.valor = val + "";
    },
    inlineSet: function (val) {
      this.inline = val;
    },
    labelSet: function (val) {
      this.inline = val;
    },
    readonlySet: function (val) {
      this.readonly = val;
    },
  },
};
</script>

<style>
</style>