<template>
  <div class="text-md-center pa-4">
    <v-dialog v-model="dialog" transition="dialog-top-transition" fullscreen>
      <template v-slot:activator="{ props: activatorProps }">
        <span  class="d-md-block d-none">
        <v-btn
          prepend-icon="mdi-apps"
          size="small"
          text="Módulos"
          v-bind="activatorProps"
        ></v-btn>
        </span> 
        <span class="d-block d-md-none text-caption cursor-pointer" @click="dialog = !dialog">
            <v-icon>mdi-apps</v-icon>
            Módulos</span>
      </template>

      <v-card>
        <v-toolbar class="gradient-background_menu_superior">
          <v-btn icon="mdi-close" @click="dialog = false"></v-btn>

          <v-toolbar-title>
            <span class="text-h5 display-1 font-weight-bold"
              >Nuestros módulos</span
            >
          </v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container class="bg-blue-lighten-5 mt-2">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-title
                >Gestión de Carreras</v-expansion-panel-title
              >
              <v-expansion-panel-text>
                <v-list lines="one">
                  <v-list-item class="text-caption"
                    >Información detallada sobre horarios, servicio, convenio,
                    tarifas de las carreras por cada conductor.</v-list-item
                  >
                  <v-list-item class="text-caption"
                    >Respaldo en imagen de los vales que se haya emitido en la
                    carrera.</v-list-item
                  >
                  <v-list-item class="text-caption"
                    >Observaciones que puedan particularizar cualquier
                    información relevante con la carrera en
                    cuestion.</v-list-item
                  >
                  <v-list-item class="text-caption"
                    >Inteligencia Artificia que permite obtener datos de
                    distancia, tiempos en recorrido, tiempos en espera y
                    calcular en base a toda la información proporcionada el
                    valor real de una carrera.</v-list-item
                  >
                </v-list>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-title
                >Control de TAG</v-expansion-panel-title
              >
              <v-expansion-panel-text>
                <v-list lines="one">
                  <v-list-item class="text-caption"
                    >Información detallada de los TAG que se utilizan durante el uso de vehículos.</v-list-item
                  >
                  <v-list-item class="text-caption"
                    >Tener actualizado y en línea los gastos de TAG de su empresa.</v-list-item
                  >
                  <v-list-item class="text-caption"
                    >Cargar los TAG al sistema utilizando directamene los archivos Excel o CSV que entrega cada autopista, permitiendo así, tener un detalle exausito de estos, con fecha, portico y monto del mismo.</v-list-item
                  >
                  <v-list-item class="text-caption"
                    >Inteligencia para determinar si un peaje corresponde está o no dentro de una carrera.</v-list-item
                  >
                  <v-list-item class="text-caption"
                    >Inteligencia para determinar si un peaje corresponde está o no dentro del turno de un conductor.</v-list-item
                  >
                </v-list>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-title
                >Registro de Gastos</v-expansion-panel-title
              >
              <v-expansion-panel-text>
                <v-list lines="one">
                  <v-list-item class="text-caption"
                    >Controlar los dineros que se entregan para uso del vehículo.</v-list-item
                  >
                  <v-list-item class="text-caption"
                    >Controlar los gastos que se realizan en cada vehículo.</v-list-item
                  >
                  <v-list-item class="text-caption"
                    >Tener una bitacora con los diversos arreglos con sus correspondientes fechas.</v-list-item
                  >
                  <v-list-item class="text-caption"
                    >Permite diferenciar las responsabildades de los gastos.</v-list-item
                  >
                </v-list>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-title
                >Gestion de TAGs</v-expansion-panel-title
              >
              <v-expansion-panel-text>
                <v-list lines="one">
                  <v-list-item class="text-caption"
                    >Información detallada sobre horarios, servicio, convenio,
                    tarifas de las carreras por cada conductor.</v-list-item
                  >
                  <v-list-item class="text-caption"
                    >Respaldo en imagen de los vales que se haya emitido en la
                    carrera.</v-list-item
                  >
                  <v-list-item class="text-caption"
                    >Observaciones que puedan particularizar cualquier
                    información relevante con la carrera en
                    cuestion.</v-list-item
                  >
                  <v-list-item class="text-caption"
                    >Inteligencia Artificia que permite obtener datos de
                    distancia, tiempos en recorrido, tiempos en espera y
                    calcular en base a toda la información proporcionada el
                    valor real de una carrera.</v-list-item
                  >
                </v-list>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    };
  },
};
</script>
    
    <style>
</style>