<template>
  <v-card-text class="mt-2 mt-sm-12 border-s-lg">
    <h3 class="text-center text-katal-title">Si ya eres usuario de KATAL</h3>
    <div class="text-center  mt-sm-10 mt-2">
      <div class="text-katal-title3">Ingresa con tu usuario y clave</div>
      <div class="text-caption">
        Podrá revisar los ingresos, gastos y pagos de su vehículo.
      </div>
    </div>
    <v-form ref="form" v-model="form.validForm" @submit.prevent="submit">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" class="mt-sm-16 mt-8 border-col">
          <formmail
            labelSet="Email"
            :requeridoSet="true"
            :validatingSet="form.validating"
            :focus="focusEmail"
            ref="formEmail"
          ></formmail>
        </v-col>
        <v-col cols="12" sm="8" class="mt-1 border-col">
          <formclave
            labelSet="Clave"
            :requeridoSet="true"
            :validatingSet="form.validating"
            ref="formPassword"
          ></formclave>
        </v-col>
        <v-col cols="12" sm="8" class="mt-1 border-col">
          <v-row class="border-col">
            <!--v-col cols="12" sm="7" class="border-col">
              <v-checkbox label="Remember me" class="mt-n1"></v-checkbox>
            </v-col-->
            <v-col cols="12" sm="12">
              <span class="caption text-blue" @click="cambiarPaso(6)"
                >Recuperar Clave</span
              >
            </v-col>  
          </v-row>
        </v-col>
        <v-col cols="12" sm="8" class="mt-sm-1 border-col">
          <formSubmit
            labelSet="Ingresar"
            :validating="form.validating"
          ></formSubmit>
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>

<script>
import { enviarJsonPOST } from "@/helpers/funcionesEstandard";

import { rulesMail, rulesClave } from "@/helpers/funcionesEstandard";
import formmail from "@/components/form/email.vue";
import formclave from "@/components/form/password.vue";
import formSubmit from "@/components/form/buttonSubmitform.vue";

export default {
  data: () => ({
    form: {
      validForm: null,
      validating: null,
      banco: null,
      mail: null,
      password: null,
    },
    focusEmail: false,
    rulesMail: rulesMail,
    rulesClave: rulesClave,
  }),
  watch: {
    step: function (val) {
      this.form = {
        validForm: null,
        validating: null,
        mail: null,
        password: null,
      };
      if (val == 1) {
        this.$refs.form.reset();
        this.focusEmail = true;
      }
    },
  },
  props: {
    step: Number,
    user: Object,
  },
  components: {
    formmail,
    formclave,
    formSubmit,
  },
  mounted: function () {
    this.$emit("setUsuario", null);
  },
  methods: {
    submit: async function () {
      this.form.validating = true;
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        let fd = new FormData();
        this.form.mail = this.$refs.formEmail.valor;
        this.form.password = this.$refs.formPassword.valor;
        fd.append("email", this.form.mail);
        fd.append("clave", this.form.password);
        var json = await enviarJsonPOST("usuario/validar_ingreso_usuario", fd);

        if (json.error != 0) {
          await this.$emit("validarMensaje", json);
        } else {
          if (
            json.data.estado_usuario == 200 ||
            json.data.estado_usuario == 300
          ) {
            
            sessionStorage.setItem("correoRecuperaClave",this.form.mail); 
            this.$emit("registraCorreo", this.form.mail);
            this.$emit("cambiarPaso", 5);
          } else {
            this.$emit("cambiarPaso", 10);
            this.$emit("setUsuario", json.data);
          }
        }
      }
      this.form.validating = false;
    },
    cambiarPaso: async function (paso) {
      this.$emit("cambiarPaso", paso);
    },
  },
};
</script>