<template>
  <v-form ref="formNew" v-model="validForm" @submit.prevent="submitNew">
    <v-row align="center" justify="center" class="mt-6">
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formcombobox
          labelSet="Vehiculo"
          :itemsSet="Vehiculos"
          :autofocusSet="true"
          MensajeAyudaSet="Seleccione el servicio"
          :requeridoSet="true"
          :valorSet="form.Vehiculo"
          ref="Vehiculo"
        ></formcombobox>
      </v-col>
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formarchivo
          labelSet="Imágen recibo"
          MensajeAyudaSet="Máximo 1 fotos de 5MB c/u"
          acceptSet="image/*"
          iconoSet="mdi-camera"
          multipleSet="true"
          :requeridoSet="true"
          textoAlternativoSet="Máximo 1 fotos de 5MB c/u"
          :validatingSet="validating"
          :minFilesSet="1"
          :maxFilesSet="1"
          :autofocusSet="false"
          ref="imagenVale"
        ></formarchivo>
      </v-col>
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formtext
          labelSet="Nro. Boleta/Factura/Guía"
          iconoSet="mdi-credit-card-outline"
          :maxLengthSet="20"
          :minLengthSet="3"
          MensajeAyudaSet="Ingrese el número documento"
          :requeridoSet="true"
          textoAlternativoSet="3 a 20 caracteres"
          :validatingSet="validating"
          :valorSet="form.numeroVale"
          ref="numeroComprobante"
        ></formtext>
      </v-col>
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formfechahora
          :autofocusSet="false"
          labelSet="Fecha/Hora"
          :maxFecSet="MaxFec"
          :minFecSet="MinFec"
          :requeridoSet="true"
          :valorSet="form.fechahora"
          ref="fechaHora"
        ></formfechahora>
      </v-col>
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formnumero
          :autofocusSet="false"
          labelSet="Valor"
          :requeridoSet="true"
          :valorMaximoSet="10000000"
          :valorMinimoSet="100"
          MensajeAyudaSet="Ingrese el valor total de la compra"
          textoAlternativoSet="de 100 a 10000000"
          preIconoSet="mdi-currency-usd"
          :valorSet="form.valor"
          ref="valor"
        ></formnumero>
      </v-col>
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formnumero
          :autofocusSet="false"
          labelSet="Valor x Litro"
          :requeridoSet="true"
          :valorMaximoSet="10000000"
          :valorMinimoSet="100"
          MensajeAyudaSet="Ingrese el valor x litro"
          textoAlternativoSet="de 100 a 10000000"
          preIconoSet="mdi-currency-usd"
          :valorSet="form.valorxlitro"
          ref="valorxlitro"
        ></formnumero>
      </v-col>
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formnumero
          :autofocusSet="false"
          labelSet="Kilometraje"
          :requeridoSet="true"
          :valorMaximoSet="10000000"
          :valorMinimoSet="100"
          MensajeAyudaSet="Ingrese el kilometraje al momento de la carga"
          textoAlternativoSet="de 100 a 10000000"
          preIconoSet="mdi-signal-distance-variant"
          :valorSet="form.valorxlitro"
          ref="kilometraje"
        ></formnumero>
      </v-col>
      <v-col
        :cols="widthCol.xs"
        :xl="widthCol.xl"
        :lg="widthCol.lg"
        :md="widthCol.md"
        :sm="widthCol.sm"
        :xs="widthCol.xs"
        class="border-col"
      >
        <formarchivo
          labelSet="Otras Imagenes de Respaldo"
          MensajeAyudaSet="Máximo 2 fotos de 5MB c/u"
          acceptSet="image/*"
          iconoSet="mdi-camera"
          multipleSet="true"
          :requeridoSet="true"
          textoAlternativoSet="Máximo 2 fotos de 5MB c/u"
          :validatingSet="validating"
          :minFilesSet="1"
          :maxFilesSet="2"
          :autofocusSet="false"
          ref="otrasFotos"
        ></formarchivo>
      </v-col>
      <v-col cols="12" class="border-col">
        <formtextoGrande
          :autofocusSet="false"
          labelSet="Observación"
          :requeridoSet="false"
          :minLengthSet="0"
          :maxLengthSet="200"
          MensajeAyudaSet="Ingrese observaciones de la carrera"
          textoAlternativoSet="de 0 a 200"
          preIconoSet="mdi-caps-lock"
          :valorSet="form.observacion"
          ref="observacion"
        ></formtextoGrande>
      </v-col>

      <v-col cols="12" class="border-col">
        <v-row>
          <v-col cols="6">
            <formbtnsend
              labelSet="Guardar"
              :validating="validating"
            ></formbtnsend>
          </v-col>
          <v-col cols="6">
            <formbtncancel
              labelSet="Volver"
              @accion="volver"
              :validating="validating"
            ></formbtncancel>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  obtenerJsonGET,
  enviarJsonPOST,
  formatoFechaHoraVue,
} from "@/helpers/funcionesEstandard";
import formarchivo from "@/components/form/archivos.vue";
import formbtnsend from "@/components/form/buttonSubmitform.vue";
import formbtncancel from "@/components/form/buttonCancelform.vue";
import formtext from "@/components/form/textos.vue";
import formfechahora from "@/components/form/fechaHora.vue";
import formnumero from "@/components/form/numero.vue";
import formtextoGrande from "@/components/form/textoGrande.vue";
import formcombobox from "@/components/form/combobox.vue";

export default {
  data: () => ({
    widthCol: {
      xl: 2,
      lg: 3,
      md: 4,
      sm: 6,
      xs: 12,
    },
    validForm: null,
    Vehiculos: [],
    validating: false,
    MaxFec: null,
    MinFec: null,
    form: {
      Vehiculo: null,
      numeroVale: null,
      fechahora: null,
      valor: null,
      valorxlitro: null,
      observacion: null,
    },
  }),
  components: {
    formarchivo,
    formbtnsend,
    formbtncancel,
    formtext,
    formfechahora,
    formnumero,
    formtextoGrande,
    formcombobox,
  },
  mounted: function () {
    this.validating = true;
    this.setFullLoading(true, "Cargando información...");
    var fec = new Date();
    this.MaxFec = formatoFechaHoraVue(fec);
    var fec2 = new Date();
    var numberOfMlSeconds = fec2.getTime();
    var addMlSeconds = 30 * 24 * 60 * 60000;
    var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
    this.MinFec = formatoFechaHoraVue(newDateObj);
    this.validating = false;
    this.setFullLoading(false, null);
    this.obtenerVehiculos();
  },
  methods: {
    submitNew: async function () {
      const { valid } = await this.$refs.formNew.validate();
      if (valid) {
        this.validating = true;
        this.setFullLoading(true, "Guardando carrera...");
        if (this.$refs.otrasFotos.valor) {
          for (var x = 0; x < this.$refs.otrasFotos.valor.length; x++) {
            let size = this.$refs.otrasFotos.valor[x].size / 1024 / 1024;
            let date = this.$refs.otrasFotos.valor[x].lastModifiedDate;
            if (size > 5) {
              let obj = {
                error: 1,
                message:
                  "Los archivos adjuntos no pueden superar 5MB, por favor corregir.",
              };
              this.validating = false;
              this.setFullLoading(
                false,
                "Guardando carrera " + this.msgSend + "..."
              );
              this.validarMensaje(obj);
              return false;
            }
          }
        }
        let fd = new FormData();
        fd.append("fotoVale", this.$refs.imagenVale.valor[0]);
        if (this.$refs.otrasFotos.valor) {
          for (let x = 0; x < this.$refs.otrasFotos.valor.length; x++) {
            fd.append("fotos[]", this.$refs.otrasFotos.valor[x]);
          }
        }
        fd.append("vehiculo_id", this.$refs.Vehiculo.valor.cod);
        fd.append("numeroComprobante", this.$refs.numeroComprobante.valor);
        fd.append("fechahora", this.$refs.fechaHora.valor);
        fd.append("valor", this.$refs.valor.valor);
        fd.append("valorxlitro", this.$refs.valorxlitro.valor);
        fd.append("kilometraje", this.$refs.kilometraje.valor);
        fd.append("observacion", this.$refs.observacion.valor);

        var json = await enviarJsonPOST("combustible/nuevo", fd);
        if (json.error == 0) {
          this.$router.go(-1);
          this.validarMensaje(json);
        } else {
          this.validarMensaje(json);
        }
        this.validating = false;
        this.setFullLoading(false, "Guardando carrera " + this.msgSend + "...");
      }
    },
    obtenerVehiculos: async function () {
      this.setFullLoading(true, "Buscando vehiculos...");
      this.buttonLoading = true;
      let fd = new FormData();
      var json = await obtenerJsonGET(
        "vehiculo/obtener_listado_vehiculo_usuario",
        null
      );
      if (json.error == 0) {
        if (json.data) {
          this.Vehiculos = json.data;
        }
      } else {
        this.validarMensaje(json);
      }
      this.setFullLoading(false, null);
    },
    volver: function () {
      this.$emit("volver");
    },
    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
    setFullLoading: async function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
  },
};
</script>

<style>
</style>