<template>
  <div class="text-md-center pa-4" v-if="!user.usuarioId">
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="400"
    >
      <template v-slot:activator="{ props: activatorProps }">
        <span class="d-md-block d-none">
          <v-btn
            prepend-icon="mdi-account"
            size="small"
            text="Iniciar Sesion"
            v-bind="activatorProps"
          ></v-btn>
        </span>
        <span
          class="d-block d-md-none text-caption cursor-pointer"
          @click="dialog = !dialog"
        >
          <v-icon>mdi-account</v-icon>
          Iniciar Sesion</span
        >
      </template>

      <v-card>
        <v-toolbar class="gradient-background_menu_superior">
          <v-btn icon="mdi-close" @click="dialog = false"></v-btn>

          <v-toolbar-title>
            <span class="text-h5 display-1 font-weight-bold"
              >Ingreso de usuarios</span
            >
          </v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container>
          <v-card-text class="border-s-lg">
            <v-form
              ref="form"
              v-model="form.validForm"
              @submit.prevent="submit"
            >
              <v-row align="center" justify="center">
                <v-col cols="12" sm="8" class="border-col">
                  <formmail
                    labelSet="Email"
                    :requeridoSet="true"
                    :validatingSet="form.validating"
                    :focus="focusEmail"
                    ref="formEmail"
                  ></formmail>
                </v-col>
                <v-col cols="12" sm="8" class="mt-1 border-col">
                  <formclave
                    labelSet="Clave"
                    :requeridoSet="true"
                    :validatingSet="form.validating"
                    ref="formPassword"
                  ></formclave>
                </v-col>
                <v-col cols="12" sm="8" class="mt-1 border-col">
                  <v-row class="border-col">
                    <!--v-col cols="12" sm="7" class="border-col">
              <v-checkbox label="Remember me" class="mt-n1"></v-checkbox>
            </v-col-->
                    <v-col cols="12" sm="12">
                      <span class="caption text-blue" @click="cambiarPaso(6)"
                        >Recuperar Clave</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="8" class="mt-sm-1 border-col">
                  <formSubmit
                    labelSet="Ingresar"
                    :validating="form.validating"
                  ></formSubmit>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
    
    <script>
import { enviarJsonPOST } from "@/helpers/funcionesEstandard";

import { rulesMail, rulesClave } from "@/helpers/funcionesEstandard";
import formmail from "@/components/form/email.vue";
import formclave from "@/components/form/password.vue";
import formSubmit from "@/components/form/buttonSubmitform.vue";

export default {
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,

      form: {
        validForm: null,
        validating: null,
        banco: null,
        mail: null,
        password: null,
      },
      focusEmail: false,
      rulesMail: rulesMail,
      rulesClave: rulesClave,
    };
  },
  props: {
    user: Object,
    step: Number,
  },
  components: {
    formmail,
    formclave,
    formSubmit,
  },
  mounted: function () {
    this.$emit("setUsuario", null);
  },
  methods: {
    submit: async function () {
      this.form.validating = true;
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        let fd = new FormData();
        this.form.mail = this.$refs.formEmail.valor;
        this.form.password = this.$refs.formPassword.valor;
        fd.append("email", this.form.mail);
        fd.append("clave", this.form.password);
        var json = await enviarJsonPOST("usuario/validar_ingreso_usuario", fd);

        this.dialog = false;
        if (json.error != 0) {
          await this.$emit("validarMensaje", json);
        } else {
          if (
            json.data.estado_usuario == 200 ||
            json.data.estado_usuario == 300
          ) {
            sessionStorage.setItem("correoRecuperaClave", this.form.mail);
            this.$emit("registraCorreo", this.form.mail);
            this.$emit("cambiarPaso", 5);
          } else {
            this.$emit("setUsuario", json.data);
            this.$router.push("/menuPrincipal");
          }
        }
      }
      this.form.validating = false;
    },
    cambiarPaso: async function (paso) {
      this.$emit("cambiarPaso", paso);
    },
  },
};
</script>
    
    <style>
</style>