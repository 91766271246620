<template>
  <v-window v-model="onboarding" show-arrows>
    <template v-slot:prev="{ props }">
      <v-btn
        density="default"
        icon="mdi-page-previous-outline"
        color="success"
        @click="props.onClick"
      >
      </v-btn>
    </template>
    <template v-slot:next="{ props }">
      <v-btn
        density="default"
        icon="mdi-page-next-outline"
        color="success"
        @click="props.onClick"
      >
      </v-btn>
    </template>
    <v-window-item key="0" class="ma-3">
      <v-form ref="formNew" v-model="validForm" @submit.prevent="submitNew">
        <v-row align="center" justify="center" class="mt-6">
          <v-col
            :cols="12"
            :xl="6"
            :lg="3"
            :md="4"
            :sm="6"
            :xs="12"
            class="border-col"
          >
            <formcombobox
              labelSet="Servicio"
              :itemsSet="servicios"
              :autofocusSet="!id"
              MensajeAyudaSet="Seleccione el servicio"
              :requeridoSet="true"
              :valorSet="form.Servicio"
              @SetValor="recalculaServicio"
              ref="Servicio"
              :readonlySet="!editForm"
              v-if="editForm"
            ></formcombobox>
            <span v-if="!editForm" class="text-caption">
              Servicio:<br />
              <span class="text-body-2">{{ form.Servicio.desc }}</span>
            </span>
          </v-col>
          <v-col
            :cols="12"
            :xl="2"
            :lg="3"
            :md="3"
            :sm="6"
            :xs="12"
            class="border-col"
          >
            <formcombobox
              labelSet="Vehiculo"
              :itemsSet="Vehiculos"
              MensajeAyudaSet="Seleccione el servicio"
              :requeridoSet="true"
              :valorSet="form.Vehiculo"
              ref="Vehiculo"
              :readonlySet="!editForm"
              v-if="editForm"
            ></formcombobox>
            <span v-if="!editForm" class="text-caption">
              Movil:<br />
              <span class="text-body-2">{{ form.Vehiculo.desc }}</span>
            </span>
          </v-col>
          <v-col
            :cols="6"
            :xl="2"
            :lg="3"
            :md="2"
            :sm="6"
            :xs="6"
            class="border-col"
          >
            <formnumero
              :autofocusSet="false"
              labelSet="Pasajeros"
              :requeridoSet="true"
              :valorMaximoSet="50"
              :valorMinimoSet="1"
              MensajeAyudaSet="Ingrese la cantidad de Pasajeros"
              textoAlternativoSet="de 1 a 50"
              preIconoSet="mdi-hail"
              @SetValor="SetPasajero"
              :valorSet="form.Pasajeros"
              ref="Pasajeros"
              :readonlySet="!editForm"
              v-if="editForm"
            ></formnumero>
            <span v-if="!editForm" class="text-caption">
              Pasajeros:<br />
              <span class="text-body-2">{{
                formatearNumero(form.Pasajeros)
              }}</span>
            </span>
          </v-col>
          <v-col
            :cols="6"
            :xl="2"
            :lg="3"
            :md="3"
            :sm="6"
            :xs="6"
            class="border-col"
          >
            <formFechaHora
              :autofocusSet="false"
              labelSet="Fecha/Hora cita pasajero"
              :maxFecSet="MaxFec"
              :minFecSet="MinFec"
              :requeridoSet="true"
              :valorSet="form.FechaHora"
              ref="FechaHora"
              :readonlySet="!editForm"
              @SetValueChange="buscarDireccion"
              v-if="editForm"
            ></formFechaHora>
            <span v-if="!editForm" class="text-caption">
              Fecha/hora servicio:<br />
              <span class="text-body-2">{{
                formatoFechaHoraTXT(form.FechaHora)
              }}</span>
            </span>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="5" class="border-col">
            <formtext
              labelSet="Dirección de Origen"
              iconoSet="mdi-car-back"
              :autofocusSet="false"
              :maxLengthSet="50"
              :minLengthSet="10"
              MensajeAyudaSet="Ingrese dirección de origen [calle - numero - comuna - region]"
              :requeridoSet="true"
              textoAlternativoSet="10 a 50 caracteres"
              :validatingSet="validating"
              :valorSet="form.DireccionOrigen"
              @change="buscarDireccion"
              ref="DireccionOrigen"
              :readonlySet="!editForm"
              v-if="editForm"
            ></formtext>
            <span v-if="!editForm" class="text-caption">
              Direccion de Destino:<br />
              <span class="text-body-2">{{ form.DireccionOrigen }}</span>
            </span>
          </v-col>
          <v-col cols="10" md="5" class="border-col">
            <formtext
              v-for="n in form.Pasajeros"
              :key="n"
              :labelSet="
                'Dirección de Destino ' + (form.Pasajeros > 1 ? n : '')
              "
              iconoSet="mdi-car-back"
              :maxLengthSet="50"
              :minLengthSet="10"
              MensajeAyudaSet="Ingrese dirección de destino [calle - numero - comuna - region]"
              :requeridoSet="true"
              textoAlternativoSet="10 a 50 caracteres"
              :validatingSet="validating"
              @change="buscarDireccion"
              :ref="`DireccionDestino_${n - 1}`"
              :readonlySet="!editForm"
            ></formtext>
          </v-col>
          <v-col cols="2" class="border-col">
            <v-btn
              v-if="form.Pasajeros > 0"
              icon="mdi-google-maps"
              @click="abrirMaps"
              class="ma-0 pa-0"
            ></v-btn>
          </v-col>

          <v-col :cols="4" class="border-col text-caption text-center">
            Kilometros Calculados:
            <b class="text-h6">{{
              formatearNumero(
                form.KilometrosCalculados ? form.KilometrosCalculados : 0
              ) +
              (form.KilometrosCalculados > 0 ? " Kilometro" : "") +
              (form.KilometrosCalculados > 1 ? "s" : "")
            }}</b>
          </v-col>
          <v-col :cols="4" class="border-col text-caption text-center">
            Duración calculada:
            <b class="text-h6">{{
              formatearNumero(
                form.MinutosCalculados ? form.MinutosCalculados : 0
              ) +
              (form.MinutosCalculados > 0 ? " minuto" : "") +
              (form.MinutosCalculados > 1 ? "s" : "")
            }}</b
            ><br />
            con {{ form.tiempoDetenidoCalculado }} en semaforos o traficos
          </v-col>
          <v-col :cols="4" class="border-col text-center text-caption">
            Valor Calculado: {{ form.ValorCalculado }}
            <b class="text-h6">{{
              formatoMonedaChile(form.ValorCalculado ? form.ValorCalculado : 0)
            }}</b>
          </v-col>

          <v-divider class="mt-2"></v-divider>
          <v-col cols="12" class="border-col mb-2">
            <v-row>
              <v-col cols="10" class="text-left text-caption">
                Descripción Ruta
                <div v-html="`${form.DescRuta}`"></div>
              </v-col>
              <v-col cols="2">
                <v-btn
                  v-if="form.Pasajeros > 0"
                  icon="mdi-map-marker-distance"
                  @click="abrirRuta"
                  class="ma-0 pa-0"
                ></v-btn>
              </v-col>
            </v-row>
            <v-divider class="mb-2"></v-divider>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="mt-3">
          <v-col
            :cols="12"
            :xl="4"
            :lg="4"
            :md="4"
            :sm="4"
            :xs="12"
            class="border-col"
          >
            <formtext
              labelSet="Número de vale"
              iconoSet="mdi-car-back"
              :maxLengthSet="9"
              :minLengthSet="3"
              MensajeAyudaSet="Ingrese el número de vale"
              :requeridoSet="true && id > 0 && form.estado_general_id == 100"
              textoAlternativoSet="3 a 9 caracteres"
              :validatingSet="validating"
              @SetValor="SetNumeroVale"
              :valorSet="form.NumeroVale"
              ref="NumeroVale"
              :readonlySet="!editForm"
              v-if="editForm"
            ></formtext>
            <span v-if="!editForm" class="text-caption">
              No. de Vale:<br />
              <span class="text-h6">{{ form.NumeroVale }}</span>
            </span>
          </v-col>
          <v-col
            :cols="6"
            :xl="2"
            :lg="2"
            :md="2"
            :sm="4"
            :xs="6"
            class="border-col"
            v-if="editForm"
          >
            <formarchivo
              labelSet="Imágen Vale"
              MensajeAyudaSet="Máximo 1 fotos de 5MB c/u"
              acceptSet="image/*"
              iconoSet="mdi-camera"
              multipleSet="true"
              :requeridoSet="true && id > 0 && form.estado_general_id == 100"
              textoAlternativoSet="Máximo 1 fotos de 5MB c/u"
              :validatingSet="validating"
              :minFilesSet="1"
              :maxFilesSet="1"
              :autofocusSet="false"
              @SetValor="SetFotoVale"
              ref="ImagenVale"
            ></formarchivo>
          </v-col>
          <v-col
            :cols="6"
            :xl="2"
            :lg="2"
            :md="2"
            :sm="4"
            :xs="6"
            class="border-col"
          >
            <formnumero
              :autofocusSet="false"
              labelSet="Kilometros carrera"
              :requeridoSet="true"
              :valorMaximoSet="2000"
              :valorMinimoSet="1"
              MensajeAyudaSet="Ingrese la cantidad de Kilometros de la carrera"
              textoAlternativoSet="de 1 a 2000"
              preIconoSet="mdi-map-marker-distance"
              :valorSet="form.Kilometros"
              @SetValorCambia="calculaCarreraServicio"
              ref="Kilometros"
              :readonlySet="!editForm"
              v-if="editForm"
            ></formnumero>
            <span v-if="!editForm" class="text-caption">
              Kilometro carrera:<br />
              <span class="text-h6">{{
                formatearNumero(form.Kilometros) +
                " Kilometro" +
                (form.Kilometros > 1 || form.Kilometros < 1 ? "s" : "")
              }}</span>
            </span>
          </v-col>
          <v-col
            :cols="6"
            :xl="2"
            :lg="2"
            :md="2"
            :sm="4"
            :xs="6"
            class="border-col"
          >
            <formnumero
              :autofocusSet="false"
              labelSet="Tag"
              :requeridoSet="true"
              :valorMaximoSet="50"
              :valorMinimoSet="0"
              MensajeAyudaSet="Ingrese la cantidad de Tag"
              textoAlternativoSet="de 0 a 50"
              preIconoSet="mdi-boom-gate-outline"
              @SetValorCambia="calculaCarrera"
              :valorSet="form.Tag"
              ref="Tag"
              :readonlySet="!editForm"
              v-if="editForm"
            ></formnumero>
            <span v-if="!editForm" class="text-caption">
              TAG:<br />
              <span class="text-body-2">{{ form.Tag }}</span>
            </span>
          </v-col>

          <v-col
            :cols="6"
            :xl="2"
            :lg="2"
            :md="2"
            :sm="4"
            :xs="6"
            class="border-col"
          >
            <formnumero
              :autofocusSet="false"
              labelSet="Duración carrera"
              :requeridoSet="true"
              :valorMaximoSet="2000"
              :valorMinimoSet="1"
              MensajeAyudaSet="Ingrese la duración de la carrera en Minutos"
              textoAlternativoSet="de 1 a 2000"
              preIconoSet="mdi-clock-outline"
              :valorSet="form.Minutos"
              ref="Minutos"
              :readonlySet="!editForm"
              v-if="editForm"
            ></formnumero>
            <span v-if="!editForm" class="text-caption">
              Duración carrera:<br />
              <span class="text-body-2">{{
                formatearNumero(form.Minutos) +
                " minuto" +
                (form.Minutos > 1 || form.Minutos < 1 ? "s" : "")
              }}</span>
            </span>
          </v-col>
          <v-col
            :cols="6"
            :xl="2"
            :lg="2"
            :md="2"
            :sm="4"
            :xs="6"
            class="border-col"
          >
            <formnumero
              :autofocusSet="false"
              labelSet="Espera al pasajero"
              :requeridoSet="true"
              :valorMaximoSet="120"
              :valorMinimoSet="0"
              MensajeAyudaSet="Ingrese los Minutos de Espera desde la cita"
              textoAlternativoSet="de 0 a 120"
              preIconoSet="mdi-timer-off-outline"
              :valorSet="form.Espera"
              @SetValorCambia="calculaCarrera"
              ref="Espera"
              :readonlySet="!editForm"
              v-if="editForm"
            ></formnumero>
            <span v-if="!editForm" class="text-caption">
              Espera al pasajero:<br />
              <span class="text-h6">{{
                formatearNumero(form.Espera) +
                " minuto" +
                (form.Espera > 1 || form.Espera < 1 ? "s" : "")
              }}</span>
            </span>
          </v-col>
          <v-col
            :cols="6"
            :xl="2"
            :lg="2"
            :md="2"
            :sm="4"
            :xs="6"
            class="border-col"
          >
            <formnumero
              :autofocusSet="false"
              labelSet="Detención en carrera"
              :requeridoSet="true"
              :valorMaximoSet="120"
              :valorMinimoSet="0"
              MensajeAyudaSet="Ingrese los minutos de detención en carrera como trafico o semaforos"
              textoAlternativoSet="de 0 a 120"
              preIconoSet="mdi-clock-end"
              :valorSet="form.tiempoDetenido"
              @SetValorCambia="calculaCarrera"
              ref="tiempoDetenido"
              :readonlySet="!editForm"
              v-if="editForm"
            ></formnumero>
            <span v-if="!editForm" class="text-caption">
              Detención en carrera:<br />
              <span class="text-h6">{{
                formatearNumero(form.tiempoDetenido) +
                " minuto" +
                (form.tiempoDetenido > 1 || form.tiempoDetenido < 1 ? "s" : "")
              }}</span>
            </span>
          </v-col>
          <v-col
            :cols="6"
            :xl="2"
            :lg="2"
            :md="3"
            :sm="4"
            :xs="6"
            class="border-col"
          >
            <formnumero
              :autofocusSet="false"
              labelSet="Valor"
              :requeridoSet="
                true && this.id > 0 && this.form.estado_general_id == 100
              "
              :valorMaximoSet="10000000"
              :valorMinimoSet="100"
              MensajeAyudaSet="Ingrese el valor total de la carrera"
              textoAlternativoSet="de 100 a 10000000"
              preIconoSet="mdi-currency-usd"
              :valorSet="form.Valor"
              ref="Valor"
              :readonlySet="!editForm"
              v-if="editForm"
            ></formnumero>
            <span v-if="!editForm" class="text-caption">
              Valor:<br />
              <span class="text-h6">{{
                formatoMonedaChile(form.Valor)
              }}</span>
            </span>
          </v-col>
          <v-col
            :cols="6"
            :xl="2"
            :lg="2"
            :md="4"
            :sm="4"
            :xs="6"
            class="border-col"
            v-if="editForm"
          >
            <formarchivo
              labelSet="Otras Imagenes de Respaldo"
              MensajeAyudaSet="Máximo 2 fotos de 5MB c/u"
              acceptSet="image/*"
              iconoSet="mdi-camera"
              multipleSet="true"
              :requeridoSet="true && id > 0 && form.estado_general_id == 100"
              textoAlternativoSet="Máximo 2 fotos de 5MB c/u"
              :validatingSet="validating"
              :minFilesSet="1"
              :maxFilesSet="2"
              :autofocusSet="false"
              ref="OtrasFotos"
            ></formarchivo>
          </v-col>
        </v-row>

        <v-row align="center" justify="center" class="mt-6">
          <v-col
            :cols="widthCol.xs"
            :xl="widthCol.xl"
            :lg="widthCol.lg"
            :md="widthCol.md"
            :sm="widthCol.sm"
            :xs="widthCol.xs"
            class="border-col text-center"
          >
            <formCheckBox
              :inlineSet="true"
              :opciones="sino"
              labelSet="¿Efectivo?"
              @SetValor="SetEfectivo"
              :valorSet="form.Efectivo"
              ref="Efectivo"
              :readonlySet="!editForm"
              v-if="editForm"
            ></formCheckBox>
            <span v-if="!editForm" class="text-caption">
              ¿Tarifa Fija?
              <span>{{ form.Efectivo == 1 ? "Si" : "No" }}</span>
            </span>
          </v-col>
          <v-col
            :cols="widthCol.xs"
            :xl="widthCol.xl"
            :lg="widthCol.lg"
            :md="widthCol.md"
            :sm="widthCol.sm"
            :xs="widthCol.xs"
            class="border-col"
          >
            <formCheckBox
              :inlineSet="true"
              :opciones="sino"
              labelSet="¿Tarifa Fija?"
              @SetValor="SetTarifaFija"
              :valorSet="form.TarifaFija"
              ref="TarifaFija"
              :readonlySet="!editForm"
              v-if="editForm"
            ></formCheckBox>
            <span v-if="!editForm" class="text-caption">
              ¿Tarifa Fija?
              <span>{{ form.TarifaFija == 1 ? "Si" : "No" }}</span>
            </span>
          </v-col>
          <v-col cols="12" class="border-col">
            <formtextoGrande
              :autofocusSet="false"
              labelSet="Observación"
              :requeridoSet="false"
              :minLengthSet="0"
              :maxLengthSet="500"
              MensajeAyudaSet="Ingrese observaciones de la carrera"
              textoAlternativoSet="de 0 a 50"
              preIconoSet="mdi-caps-lock"
              :valorSet="form.Observacion"
              ref="Observacion"
              :readonlySet="!editForm"
              v-if="editForm"
            ></formtextoGrande>
            <span v-if="!editForm" class="text-caption">
              Observacion:
              <span>{{ form.Observacion }}</span>
            </span>
          </v-col>
          <v-col cols="12">
            <v-row align="center" justify="center">
              <v-col cols="6" v-if="editForm">
                <formbtnsend
                  labelSet="Guardar"
                  :validating="validating"
                ></formbtnsend>
              </v-col>
              <v-col cols="6">
                <formbtncancel
                  labelSet="Volver"
                  @accion="volver"
                  :validating="validating"
                ></formbtncancel>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-window-item>

    <v-window-item v-for="i in form.img" :key="i.id" class="ma-3">
      <v-row>
        <v-col cols="12">
          <v-img :src="i.img"></v-img>
        </v-col>
        <v-col cols="12">
          <formbtncancel
            labelSet="Volver"
            @accion="volver"
            :validating="validating"
          ></formbtncancel>
        </v-col>
      </v-row>
    </v-window-item>
  </v-window>
  <mapaVue
    :pasos="form.steps"
    :dialog="map.dialog"
    @cerrarMap="cerrarMap"
  ></mapaVue>
</template>

<script>
import {
  obtenerJsonGET,
  enviarJsonPOST,
  formatoFechaHoraVue,
  formatoFechaHoraTXT,
  formatearNumeroComoPorcentaje,
  formatoMonedaChile,
  formatearNumero,
} from "@/helpers/funcionesEstandard";
import formarchivo from "@/components/form/archivos.vue";
import formnumero from "@/components/form/numero.vue";
import formFechaHora from "@/components/form/fechaHora.vue";
import formtextoGrande from "@/components/form/textoGrande.vue";
import formCheckBox from "@/components/form/checkBox.vue";
import formcombobox from "@/components/form/combobox.vue";
import formtext from "@/components/form/textos.vue";
import formbtnsend from "@/components/form/buttonSubmitform.vue";
import formbtncancel from "@/components/form/buttonCancelform.vue";
import mapaVue from "./mapa.vue";

export default {
  data: () => ({
    onboarding: 0,
    isLoadCarrera: false,
    previousUrl: "",
    carrera: {
      bajada_bandera: 2100,
      val_kilometro: 750,
      minuto_Espera_libre: 15,
      val_minuto_Espera: 150,
      val_Tag: 900,
    },
    findAlternativaRuta: {
      origen: null,
      destino: null,
    },
    widthCol: {
      xl: 2,
      lg: 3,
      md: 4,
      sm: 6,
      xs: 12,
    },
    widthColNum: {
      xl: 2,
      lg: 2,
      md: 2,
      sm: 4,
      xs: 6,
    },
    validating: null,
    validForm: null,
    editForm: true,
    form: {
      Servicio: null,
      Vehiculo: null,
      Pasajeros: 1,
      FechaHora: null,
      DireccionOrigen: null,
      DireccionDestino: null,
      NumeroVale: null,
      ImagenVale: null,
      Kilometros: null,
      KilometrosCalculados: null,
      Tag: null,
      Minutos: null,
      MinutosCalculados: null,
      Espera: null,
      tiempoDetenido: null,
      tiempoDetenidoCalculado: null,
      Valor: null,
      ValorCalculado: null,
      OtrasFotos: null,
      Efectivo: "2",
      TarifaFija: "2",
      DescRuta: "",
      Observacion: "",
      steps: null,
      img: [],
    },
    map: {
      dialog: false,
      origin: null,
      destination: null,
      waypoints: null,
    },
    MaxFec: null,
    MinFec: null,
    sino: [
      { label: "Si", valor: 1 },
      { label: "No", valor: 2 },
    ],
    servicios: [],
    formatearNumeroComoPorcentaje: formatearNumeroComoPorcentaje,
    formatoMonedaChile: formatoMonedaChile,
    formatearNumero: formatearNumero,
    formatoFechaHoraVue: formatoFechaHoraVue,
    formatoFechaHoraTXT: formatoFechaHoraTXT,
  }),
  watch: {},
  props: {
    id: Number,
  },
  components: {
    formnumero,
    formarchivo,
    formFechaHora,
    formtextoGrande,
    formcombobox,

    formtext,
    formbtnsend,
    formbtncancel,
    formCheckBox,

    mapaVue,
  },
  mounted: async function () {
    this.setFullLoading(true, "Cargando Datos...");
    var fec = new Date();
    fec.setHours(fec.getHours() + 12);
    this.MaxFec = formatoFechaHoraVue(fec);
    var fec2 = new Date();
    var numberOfMlSeconds = fec2.getTime();
    var addMlSeconds = 30 * 24 * 60 * 60000;
    var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
    this.MinFec = formatoFechaHoraVue(newDateObj);
    this.setFullLoading(false, null);
    await this.obtenerServicios();
    await this.obtenerVehiculos();
    if (!isEmpty(this.id)) {
      this.isLoadCarrera = true;
      this.$nextTick(() => {
        this.obtenerCarrera();
      });
    }
  },
  methods: {
    recalculaServicio: async function (obj) {
        if (!isEmpty(this.$refs.Servicio.valor)) {
          if (this.$refs.Servicio.valor.bajada_bandera) {
            this.carrera.bajada_bandera = parseFloat(
              this.$refs.Servicio.valor.bajada_bandera
            );
          }
          if (this.$refs.Servicio.valor.valor_kilometro) {
            this.carrera.valor_kilometro = parseFloat(
              this.$refs.Servicio.valor.valor_kilometro
            );
          }
          if (this.$refs.Servicio.valor.minuto_Espera_libre) {
            this.carrera.minuto_Espera_libre = parseFloat(
              this.$refs.Servicio.valor.minuto_Espera_libre
            );
          }
          if (this.$refs.Servicio.valor.valor_minuto) {
            this.carrera.valor_minuto = parseFloat(
              this.$refs.Servicio.valor.valor_minuto
            );
          }
          if (this.$refs.Servicio.valor.valor_tag) {
            this.carrera.valor_tag = parseFloat(
              this.$refs.Servicio.valor.valor_tag
            );
          }
        await this.calculaCarrera();
      }
    },
    obtenerCarrera: async function () {
      this.setFullLoading(true, "Obteniendo carrera " + this.msgSend + "...");

      let fd = new FormData();
      fd.append("detalle", this.id);

      var json = await enviarJsonPOST("carrera/obtener", fd);
      if (json.error == 0) {
        this.form.Servicio = json.data.servicio;
        this.form.Vehiculo = json.data.vehiculo;

        this.carrera.valor_kilometro = json.data.valor_kilometro;
        this.carrera.val_minuto_Espera = json.data.valor_minuto;
        this.carrera.val_Tag = json.data.valor_tag;
        this.carrera.bajada_bandera = json.data.bajada_bandera;

        this.form.Pasajeros = json.data.cantidad_pasajeros;
        this.form.FechaHora = json.data.fechahora;
        this.form.DireccionOrigen = json.data.direccion_origen;

        if (!isEmpty(json.data.comprobante)) {
          this.form.NumeroVale = json.data.comprobante;
        }
        this.form.Kilometros = json.data.kilometros;
        this.form.KilometrosCalculados = json.data.KilometrosCalculados;
        this.form.Tag = json.data.tag;
        this.form.Minutos = json.data.Minutos;
        this.form.MinutosCalculados = json.data.MinutosCalculados;
        this.form.Espera = json.data.espera;
        this.form.tiempoDetenido = json.data.tiempoDetenido;
        this.form.tiempoDetenidoCalculado = json.data.tiempoDetenidoCalculado;
        this.form.Valor = json.data.valor_conductor;
        this.form.ValorCalculado = json.data.valor_calculado;
        this.form.Efectivo = json.data.efectivo;
        this.form.TarifaFija = json.data.tarifa_fija;
        this.form.DescRuta = json.data.DescRuta;
        this.form.Observacion = json.data.observacion;
        this.form.img = json.data.img;
        this.form.steps = json.data.steps;
        this.form.estado_general_id = json.data.estado_general_id;
        this.form.DireccionDestino = json.data.direccion_destino;

        setTimeout(this.carga_destinos, 500);

        if (json.data.estado_general_id != 100 && json.data.estado_general_id != 200) {
          this.editForm = false;
        }
      } else {
        this.validarMensaje(json);
      }
      this.validating = false;
      this.setFullLoading(false, null);
    },
    carga_destinos: function () {
      for (let x = 0; x < this.form.Pasajeros; x++) {
        this.$refs[`DireccionDestino_${x}`][0].valor =
          this.form.DireccionDestino[x];
      }
    },

    cerrarMap: function () {
      this.map.dialog = false;
    },
    calculaCarreraServicio: async function () {
        if (isEmpty(this.form.KilometrosCalculados)) {
          this.form.KilometrosCalculados = this.$refs.Kilometros.valor;
        }
        this.calculaCarrera();
    },
    calculaCarrera: async function () {
        let Kilometros = !isEmpty(this.form.KilometrosCalculados)
          ? parseFloat(this.form.KilometrosCalculados)
          : arguments.length > 0
          ? arguments[0]
          : 0;

        if (isEmpty(this.$refs.Tag.valor)) {
          this.form.Tag = 0.0;
        }
        if (isEmpty(this.$refs.Espera.valor)) {
          this.form.Espera = 0.0;
        }
        if (isEmpty(this.form.tiempoDetenido)) {
          this.form.tiempoDetenido = 0.0;
        }
        if (Kilometros) {
          let cambiaValor = this.form.Valor == this.form.ValorCalculado;

          let Espera =
            !isEmpty(this.$refs.Espera.valor) &&
            parseFloat(this.$refs.Espera.valor) >
              this.carrera.minuto_Espera_libre
              ? parseFloat(this.$refs.Espera.valor)
              : 0;
          let Tag = !isEmpty(this.$refs.Tag.valor)
            ? parseFloat(this.$refs.Tag.valor)
            : 0;
          const factor = Math.pow(10, 0);

          this.form.ValorCalculado =
            Math.round(
              parseFloat(this.form.KilometrosCalculados) *
                parseFloat(this.carrera.val_kilometro) +
                (parseFloat(this.form.Espera) -
                  parseFloat(this.carrera.minuto_Espera_libre) >
                0
                  ? (parseFloat(this.form.Espera) -
                      parseFloat(this.carrera.minuto_Espera_libre)) *
                    parseFloat(this.carrera.val_minuto_Espera)
                  : 0) +
                parseFloat(this.form.tiempoDetenidoCalculado) *
                  parseFloat(this.carrera.val_minuto_Espera) +
                parseFloat(Tag) * parseFloat(this.carrera.val_Tag) +
                parseFloat(this.carrera.bajada_bandera) * factor
            ) / factor;

          this.form.Valor =
            Math.round(
              parseFloat(this.form.Kilometros) *
                parseFloat(this.carrera.val_kilometro) +
                (parseFloat(this.form.Espera) -
                  parseFloat(this.carrera.minuto_Espera_libre) >
                0
                  ? (parseFloat(this.form.Espera) -
                      parseFloat(this.carrera.minuto_Espera_libre)) *
                    parseFloat(this.carrera.val_minuto_Espera)
                  : 0) +
                parseFloat(this.form.tiempoDetenido) *
                  parseFloat(this.carrera.val_minuto_Espera) +
                parseFloat(Tag) * parseFloat(this.carrera.val_Tag) +
                parseFloat(this.carrera.bajada_bandera) * factor
            ) / factor;
        }
    },
    submitNew: async function () {
        const { valid } = await this.$refs.formNew.validate();
        if (valid) {
          //this.validating = true;
          // this.setFullLoading(true, "Guardando carrera...");

          let fd = new FormData();
          fd.append("ID", this.id);
          fd.append("Servicio", this.$refs.Servicio.valor.cod);
          fd.append("Vehiculo", this.$refs.Vehiculo.valor.cod);
          fd.append("Pasajeros", this.$refs.Pasajeros.valor);
          fd.append("FechaHora", this.$refs.FechaHora.valor);
          fd.append("DireccionOrigen", this.$refs.DireccionOrigen.valor);

          for (let x = 0; x < this.$refs.Pasajeros.valor; x++) {
            fd.append(
              "DireccionDestino[]",
              this.$refs[`DireccionDestino_${x}`][0].valor
            );
          }

          fd.append("NumeroVale", this.$refs.NumeroVale.valor);
          if (this.$refs.OtrasFotos.valor) {
            for (var x = 0; x < this.$refs.OtrasFotos.valor.length; x++) {
              let size = this.$refs.OtrasFotos.valor[x].size / 1024 / 1024;
              let date = this.$refs.OtrasFotos.valor[x].lastModifiedDate;
              if (size > 5) {
                let obj = {
                  error: 1,
                  message:
                    "Los archivos adjuntos no pueden superar 5MB, por favor corregir.",
                };
                this.validating = false;
                this.setFullLoading(
                  false,
                  "Guardando carrera " + this.msgSend + "..."
                );
                this.validarMensaje(obj);
                return false;
              }
            }
          }
          if (this.$refs.ImagenVale.valor) {
            fd.append("fotoVale", this.$refs.ImagenVale.valor[0]);
          }

          fd.append("Kilometros", this.$refs.Kilometros.valor);

          fd.append("KilometrosCalculados", this.form.KilometrosCalculados);

          fd.append("Tag", this.$refs.Tag.valor);
          fd.append("Minutos", this.$refs.Minutos.valor);
          fd.append("MinutosCalculados", this.form.MinutosCalculados);
          fd.append("Espera", this.$refs.Espera.valor);
          fd.append("tiempoDetenido", this.$refs.tiempoDetenido.valor);
          fd.append("tiempoDetenidoCalculado", this.form.tiempoDetenidoCalculado);
          fd.append("Valor", this.$refs.Valor.valor);
          fd.append("ValorCalculado", this.form.ValorCalculado);

          if (this.$refs.OtrasFotos.valor) {
            for (let x = 0; x < this.$refs.OtrasFotos.valor.length; x++) {
              fd.append("fotos[]", this.$refs.OtrasFotos.valor[x]);
            }
          }

          fd.append("Efectivo", this.$refs.Efectivo.valor);
          fd.append("TarifaFija", this.$refs.TarifaFija.valor);
          fd.append("DescRuta", this.form.DescRuta  );
          fd.append("Observacion", this.$refs.Observacion.valor);

          fd.append("valor_kilometro", this.carrera.val_kilometro);
          fd.append("valor_minuto", this.carrera.val_minuto_Espera);
          fd.append("valor_Tag", this.carrera.val_Tag);
          fd.append("bajada_bandera", this.carrera.bajada_bandera);

          const arregloEscapado = this.form.steps.map((objeto) =>
            escaparComillasEnObjeto(objeto)
          );

          // Convertir a JSON
          const jsonString = JSON.stringify(arregloEscapado);
          fd.append("steps", jsonString);

          var json = await enviarJsonPOST("carrera/guardar", fd);
          if (json.error == 0) {
            this.$router.push("/menuPrincipal");
            this.validarMensaje(json);
          } else {
            this.validarMensaje(json);
          }
          this.validating = false;
          this.setFullLoading(
            false,
            "Guardando carrera " + this.msgSend + "..."
          );
        }
    },
    buscarDireccion: async function () {
        let fd = new FormData();
        let busca = true;
        busca = busca && !isEmpty(this.$refs.DireccionOrigen.valor);

        for (let x = 0; x < this.$refs.Pasajeros.valor; x++) {
          busca =
            busca && !isEmpty(this.$refs[`DireccionDestino_${x}`][0].valor);
          fd.append("destino[]", this.$refs[`DireccionDestino_${x}`][0].valor);
        }

        if (busca) {
          this.setFullLoading(true, "Obteniendo rutas...");
          this.buttonLoading = true;

          fd.append("origen", this.$refs.DireccionOrigen.valor);
          fd.append("departureTime", this.$refs.FechaHora.valor);

          var json = await enviarJsonPOST("Serviciosexternos/mapa", fd);
          this.form.steps = [];
          if (json.error == 0) {
            if (json.data) {
              let cambioKilometro =
                isEmpty(this.form.Minutos) ||
                this.form.Minutos == this.form.MinutosCalculados;

              let cambiaDuracion =
                isEmpty(this.form.Minutos) ||
                this.form.Minutos == this.form.MinutosCalculados;

              let cambiaTiempoDetenido =
                isEmpty(this.form.tiempoDetenido) ||
                this.form.tiempoDetenido ==
                  (isEmpty(this.form.tiempoDetenidoCalculado)
                    ? 0
                    : this.form.tiempoDetenidoCalculado);

              const factor = Math.pow(10, 2);

              this.form.tiempoDetenidoCalculado =
                Math.round(json.data.duration_trafic * factor) / factor;

              if (cambiaTiempoDetenido) {
                this.form.tiempoDetenido = this.form.tiempoDetenidoCalculado;
              }

              this.form.KilometrosCalculados =
                Math.round(json.data.distance * factor) / factor;

              if (cambioKilometro) {
                this.form.Kilometros = this.form.KilometrosCalculados;
              }

              this.form.MinutosCalculados =
                Math.round(json.data.duration * factor) / factor;

              if (cambiaDuracion) {
                this.form.Minutos = this.form.MinutosCalculados;
              }
              let obs = "Por " + json.data.sumary + ":\r\n";
              for (let x = 0; x < json.data.legs.length; x++) {
                obs =
                  obs +
                  (x == 0
                    ? "desde " + json.data.legs[x].start + " hasta "
                    : ",\r\nluego hasta ") +
                  json.data.legs[x].end +
                  " son " +
                  json.data.legs[x].distance +
                  " km" +
                  (json.data.legs[x].distance > 0 ? "s" : "") +
                  " con una duración de " +
                  json.data.legs[x].duration +
                  " minuto" +
                  (json.data.legs[x].duration > 0 ? "s" : "");
                for (let s = 0; s < json.data.legs[x].steps.length; s++) {
                  let step = {
                    distancia: json.data.legs[x].steps[s].distance.text,
                    duracion: json.data.legs[x].steps[s].duration.text,
                    start_location: json.data.legs[x].steps[s].start_location,
                    end_location: json.data.legs[x].steps[s].end_location,
                    html_instructions:
                      json.data.legs[x].steps[s].html_instructions,
                  };
                  this.form.steps.push(step);
                }
              }
              this.map.origin = {
                lat: json.data.legs[0].start_location.lat,
                lng: json.data.legs[0].start_location.lng,
              };
              this.map.destination = {
                lat: json.data.legs[json.data.legs.length - 1].end_location.lat,
                lng: json.data.legs[json.data.legs.length - 1].end_location.lng,
              };
              this.map.waypoints = [];
              for (let i = 0; i < json.data.legs.length; i++) {
                if (i > 0) {
                  this.map.waypoints.push({
                    location: {
                      lat: json.data.legs[i].start_location.lat,
                      lng: json.data.legs[i].start_location.lng,
                    },
                  });
                }
                for (let x = 0; x < json.data.legs[i].steps.length; x++) {
                  this.map.waypoints.push({
                    location: {
                      lat: json.data.legs[i].steps[x].end_location.lat,
                      lng: json.data.legs[i].steps[x].end_location.lng,
                    },
                  });
                }
              }
              if (!isEmpty(obs)) {
                this.form.DescRuta = obs;
              }
              await this.calculaCarrera(this.form.KilometrosCalculados);
            }
          } else {
            this.validarMensaje(json);
          }
          this.setFullLoading(false, null);
        }
    },
    abrirRuta: function () {
        if (this.form.steps) {
          this.map.dialog = true;
        } else {
          let obj = {
            error: 1,
            message: "Debe tener todas las direcciones.",
            data: null,
          };
          this.validarMensaje(obj);
        }
    },
    abrirMaps: function () {
      let busca = true;
      let origin = this.form.DireccionOrigen;
      busca = busca && !isEmpty(origin);
      let destination = "";
      let waypoints = [];
      for (let x = 0; x < this.form.Pasajeros; x++) {
        busca = busca && !isEmpty(this.$refs[`DireccionDestino_${x}`][0].valor);
        if (x == this.form.Pasajeros - 1) {
          destination = this.$refs[`DireccionDestino_${x}`][0].valor;
        } else {
          waypoints.push(this.$refs[`DireccionDestino_${x}`][0].valor);
        }
      }
      busca = busca && !isEmpty(destination);

      if (busca) {
        let departureTime = 0;
        if (!isEmpty(this.form.FechaHora)) {
          departureTime = Math.floor(
            new Date(this.form.FechaHora).getTime() / 1000
          );
        }

        let url = this.buildGoogleMapsURL(
          origin,
          destination,
          waypoints,
          departureTime
        );
        window.open(url, "_blank");
      } else {
        let obj = {
          error: 1,
          message: "Debe tener todas las direcciones.",
          data: null,
        };
        this.validarMensaje(obj);
      }
    },
    buildGoogleMapsURL: function (
      origin,
      destination,
      waypoints,
      departure_time
    ) {
      const baseURL = "https://www.google.com/maps/dir/?api=1";
      const originParam = `origin=${encodeURIComponent(origin)}`;
      const destinationParam = `destination=${encodeURIComponent(destination)}`;
      const waypointsParam = !isEmpty(waypoints)
        ? `waypoints=${waypoints.map(encodeURIComponent).join("|")}`
        : "";
      const departureTimeParam = !isEmpty(departure_time)
        ? `departure_time=${departure_time}`
        : "";
      return `${baseURL}&${originParam}&${destinationParam}&${waypointsParam}&${departureTimeParam}`;
    },
    obtenerServicios: async function () {
      this.setFullLoading(true, "Buscando servicios...");
      this.buttonLoading = true;
      let fd = new FormData();
      var json = await obtenerJsonGET(
        "servicio/obtener_servicios_combobox_empresa",
        null
      );
      if (json.error == 0) {
        if (json.data) {
          this.servicios = json.data;
          if (this.servicios.length == 1) {
            this.form.Vehiculo = this.servicios[0];
          }
        }
      } else {
        this.validarMensaje(json);
      }
      this.setFullLoading(false, null);
    },
    obtenerVehiculos: async function () {
      this.setFullLoading(true, "Buscando vehículos...");
      this.buttonLoading = true;
      let fd = new FormData();
      var json = await obtenerJsonGET(
        "vehiculo/obtener_listado_vehiculo_usuario",
        null
      );
      if (json.error == 0) {
        if (json.data) {
          this.Vehiculos = json.data;
          if (this.Vehiculos.length == 1) {
            this.form.Vehiculo = this.Vehiculos[0];
          }
        }
      } else {
        this.validarMensaje(json);
      }
      this.setFullLoading(false, null);
    },
    volver: function () {
      this.$emit("volver");
    },
    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
    setFullLoading: async function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    SetPasajero: function (val) {
      if (parseInt(val) > 0) {
        this.form.Pasajeros = parseInt(val);
      } else {
        this.form.Pasajeros = 0;
      }
    },
  },
};

function escaparComillasEnObjeto(objeto) {
  const objetoEscapado = {};
  for (let clave in objeto) {
    if (objeto.hasOwnProperty(clave)) {
      let valor = objeto[clave];
      if (typeof valor === "string") {
        objetoEscapado[clave] = valor.replace(/"/g, '\\"');
      } else {
        objetoEscapado[clave] = valor;
      }
    }
  }
  return objetoEscapado;
}
</script>