<template>
  <v-row class="calendario-container">
    <v-col cols="12" class="border-col" v-if="!horas">
      <v-chip variant="outlined" class="elevation-4 pa-2">
        Carreras por día del período
        <v-chip prepend-icon="mdi-bus-clock" variant="outlined" class="ma-3">
          {{ periodo }}
        </v-chip>
        ({{ carreras.length }} carrera(s))
      </v-chip>
    </v-col>
    <v-col cols="12" md="4" class="text-start" v-if="!horas">
      <v-chip variant="outlined" class="elevation-4 pa-2 bg-cyan-lighten-5">
        {{ formatoMonedaChile(this.valorCarrera) }} Valor Carrera
      </v-chip>
    </v-col>
    <v-col cols="12" md="4" class="text-center" v-if="!horas">
      <v-chip variant="outlined" class="elevation-4 pa-2 bg-red-lighten-5">
        {{ formatoMonedaChile(this.valorCarrera - this.valorFinal) }} Descuentos
      </v-chip>
    </v-col>
    <v-col cols="12" md="4" class="text-end" v-if="!horas">
      <v-chip variant="outlined" class="elevation-4 pa-2 bg-green-lighten-5">
        {{ formatoMonedaChile(this.valorFinal) }} Valor Final
      </v-chip>
    </v-col>
    <v-col cols="12" v-if="!horas">
      <table
        width="100%"
        class="align-start"
        style="width: 100%; min-width: 600px; border-collapse: collapse"
      >
        <tr class="text-caption bg-cyan-accent-4">
          <td class="" width="25"></td>
          <td class="border text-center">Lunes</td>
          <td class="border text-center">Martes</td>
          <td class="border text-center">Miercoles</td>
          <td class="border text-center">Jueves</td>
          <td class="border text-center">Viernes</td>
          <td class="border text-center">Sábado</td>
          <td class="border text-center">Domingo</td>
        </tr>
        <tr
          class="text-caption text-center min-height-row"
          v-for="fila in filas"
          :key="fila"
        >
          <td class="border bg-cyan-lighten-4" width="25">
            {{ fila.semana }}
          </td>
          <td
            :class="`border text-center ${
              dia.dia
                ? dia.combustible.length > 0 || dia.eventos.length > 0
                  ? dia.combustible.length
                    ? dia.pago * toleranciaBencina < dia.bencina
                      ? 'bg-red-lighten-5'
                      : 'bg-green-lighten-4'
                    : 'bg-orange-lighten-4'
                  : 'bg-blue-grey-lighten-3'
                : 'bg-transparent'
            }`"
            style="vertical-align: top"
            v-for="dia in fila.dias"
            :key="dia"
            width="13%"
          >
            <table width="100%" height="100%">
              <tr class="fixed-height-row">
                <td class="text-center" style="vertical-align: top">
                  <v-btn
                    size="x-small"
                    border
                    :icon="`mdi-numeric-${dia.dia}`"
                    v-if="dia.dia && dia.eventos.length > 0"
                    @click="revisarCarreraHora(dia)"
                  >
                    {{ dia.dia }}
                  </v-btn>
                  <span v-if="dia.dia && dia.eventos.length <= 0" class="mt-6">
                    {{ dia.dia }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  <fichaCarreraVue
                    v-for="eve in dia.eventos"
                    :key="eve"
                    :carrera="eve"
                  ></fichaCarreraVue>

                  <fichaCombustible
                    v-for="com in dia.combustible"
                    :key="com"
                    :combustible="com"
                  ></fichaCombustible>

                  <fichaGasto
                    v-for="gas in dia.gastos"
                    :key="gas"
                    :gasto="gas"
                  ></fichaGasto>

                  <fichaTag v-for="t in dia.tag" :key="t" :tag="t"></fichaTag>
                </td>
              </tr>
              <tr class="fixed-height-row">
                <td
                  class="text-center"
                  v-if="dia.final"
                  style="vertical-align: bottom"
                >
                  Carrera: {{ formatoMonedaChile(dia.pago) }}<br />Final:
                  <b>{{ formatoMonedaChile(dia.final) }}</b>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </v-col>
    <v-col cols="12" v-if="horas">
      <buttonCancelform
        labelSet="Volver Calendario"
        @click="horas = null"
      ></buttonCancelform>
      <v-row align="center" justify="center" class="mt-2 mb-2">
        <v-col>
          <v-chip v-if="dia.dia - 1 > 0" @click="cambiarDia(dia.dia - 1)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-chip></v-col
        >
        <v-col>día {{ dia.dia }}</v-col>
        <v-col>
          <v-chip v-if="dia.dia + 1 < obtenerDiasEnMes(periodo)" @click="cambiarDia(dia.dia + 1)">
            <v-icon>mdi-chevron-right</v-icon>
          </v-chip>
          </v-col
        >
      </v-row>
      <div
        style="
          height: 745pt;
          min-height: 745pt;
          width: 100%;
          min-width: 600px;
          position: relative;
        "
        class="text-center rounded-lg elevation-6"
      >
        <div
          v-for="hora in horas"
          :key="hora"
          :style="hora.style"
          class="bg-blue-lighten-5 text-center text-katal-carrera-calendario"
        >
        <div class="bg-primary rounded-lg" style="width: 30pt;">
          {{ hora.hora < 10 ? "0" : "" }}{{ parseInt(hora.hora) }}:{{
            parseInt(hora.hora) != hora.hora ? "30" : "00"
          }}
          </div>
        </div>
        <v-chip
          v-for="carrera in dia.carreras"
          :key="carrera"
          :style="carrera.style"
          @click="VerDetalleCarrera(carrera)"
          class="bg-secondary text-start pl-2 text-katal-carrera-calendario rounded-lg elevation-6"
        >
          <v-icon>mdi-train-car</v-icon>
          {{ formatoMonedaChile(carrera.monto_total) }} {{ carrera.start.substring(11,16) }}
        </v-chip>
        <div
          v-for="comb in dia.combustibledet"
          :key="comb"
          :style="comb.style"
          class="bg-pink-lighten-4 text-start pl-2 text-katal-carrera-calendario rounded-lg elevation-6"
        >
          <v-icon>mdi-gas-station-outline</v-icon>
          {{ formatoMonedaChile(comb.valor) }}
        </div>
        <div
          v-for="gasto in dia.gastosdet"
          :key="gasto"
          :style="gasto.style"
          class="bg-red-lighten-3 text-start pl-2 text-katal-carrera-calendario rounded-lg elevation-6"
        >
          <v-icon>mdi-cash-multiple</v-icon>
          {{ formatoMonedaChile(gasto.valor) }}
        </div>
        <div
          v-for="tag in dia.tagdet"
          :key="tag"
          :style="tag.style"
          :class="tag.class"
        >
          <v-icon>mdi-boom-gate-up-outline</v-icon>
          {{ tag.start.substring(11,16) }} {{ formatoMonedaChile(tag.valor) }}
        </div>
      </div>
      <v-row align="center" justify="center" class="mt-2 mb-2">
        <v-col>
          <v-chip v-if="dia.dia - 1 > 0" @click="cambiarDia(dia.dia - 1)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-chip></v-col
        >
        <v-col>día {{ dia.dia }}</v-col>
        <v-col>
          <v-chip v-if="dia.dia + 1 < obtenerDiasEnMes(periodo)" @click="cambiarDia(dia.dia + 1)">
            <v-icon>mdi-chevron-right</v-icon>
          </v-chip>
          </v-col
        >
      </v-row>
      <buttonCancelform
        labelSet="Volver Calendario"
        @click="horas = null"
      ></buttonCancelform>
    </v-col>
  </v-row>
</template>

<script>
import {
  formatoMonedaChile,
  formatoFechaHoraTXT,
  agregarYOrdenarPorFechaYTipo,
  contarBloquesDe30Minutos,
} from "@/helpers/funcionesEstandard";
import fichaCarreraVue from "./fichaCarrera.vue";
import fichaCombustible from "./fichaCombustible.vue";
import fichaGasto from "./fichaGasto.vue";
import fichaTag from "./fichaTag.vue";
import buttonCancelform from "@/components/form/buttonCancelform.vue";
import { createLogger } from "vuex";
import { ar } from "vuetify/lib/locale/index.mjs";

export default {
  data: () => ({
    filas: null,
    filahoras: null,
    toleranciaBencina: 1,
    semana: 23,
    valorCarrera: 0,
    valorFinal: 0,
    formatoMonedaChile: formatoMonedaChile,
    formatoFechaHoraTXT: formatoFechaHoraTXT,
    dia: null,
    horas: null,
    meses: [
      {
        mes: "Enero",
        dias: 31,
      },
      {
        mes: "Febrero",
        dias: 28,
      },
      {
        mes: "Marzo",
        dias: 31,
      },
      {
        mes: "Abril",
        dias: 30,
      },
      {
        mes: "Mayo",
        dias: 31,
      },
      {
        mes: "Junio",
        dias: 30,
      },
      {
        mes: "Julio",
        dias: 31,
      },
      {
        mes: "Agosto",
        dias: 31,
      },
      {
        mes: "Septiembre",
        dias: 30,
      },
      {
        mes: "Octubre",
        dias: 31,
      },
      {
        mes: "Noviembre",
        dias: 30,
      },
      {
        mes: "Diciembre",
        dias: 31,
      },
    ],
  }),
  props: {
    periodo: String,
    carreras: Array,
    combustible: Array,
    gastos: Array,
    tag: Array,
  },
  components: {
    fichaCarreraVue,
    fichaCombustible,
    buttonCancelform,
    fichaGasto,
    fichaTag,
  },
  watch: {
    carreras: function (val) {
      this.lecturaInicial();
      this.lista24hrs();
      this.revisarCarreraDia();
    },
  },
  mounted: async function () {},
  methods: {
    cambiarDia: function (dia) {
      let obj = null;
      let x = 0;
      let y = 0;
      for (x = 0; x < this.filas.length; x++) {
        for (y = 0; y < this.filas[x].dias.length; y++) {
          if (parseInt(this.filas[x].dias[y].dia) == parseInt(dia)) {
            obj = this.filas[x].dias[y];
          }
        }
      }
      if (obj) {
        this.revisarCarreraHora(obj);
      }
    },
    VerDetalleCarrera: function (obj) {
      this.$router.push("/Edit/VCarrera/" + obj.detalle);
    },
    revisarCarreraHora: function (obj) {
      this.dia = obj;
      if (obj) {
        let altoBloqueHorario = 15;
        /**
         * Se parte revisando todos los valores existentes de carreras
         */
        let oldHora = 0;
        let left = 40;
        let width = 400;
        this.dia.carreras = [];
        if (obj.eventos[0]) {
          for (let x = 0; x < obj.eventos[0].detalles.length; x++) {
            this.dia.carreras = agregarYOrdenarPorFechaYTipo(
              this.dia.carreras,
              obj.eventos[0].detalles[x],
              ["detalle"],
              "start"
            );
          }
          for (let x = 0; x < this.dia.carreras.length; x++) {
            this.dia.carreras[x].bloques = (this.dia.carreras[x].minutos/60)*2;
            if (this.dia.carreras[x].bloques < 1) {
              this.dia.carreras[x].bloques = 1;
            }
            let d = new Date(this.dia.carreras[x].start);
            //this.dia.dia = d.getDate();
            let minuto = d.getMinutes();
            if (minuto > 0) {
              let a = Math.round(60 / d.getMinutes(), 2);
              minuto = altoBloqueHorario / a;
            }
            let top =
              altoBloqueHorario + altoBloqueHorario * d.getHours() * 2 + minuto;
            let height = altoBloqueHorario * this.dia.carreras[x].bloques;
            if (height + top > 735) {
              height = 735 - top;
            }
            if (oldHora == d.getHours() && oldHora != 0) {
              left += 20;
              width -= 20;
            } else {
              left = 40;
              width = 400;
            }
            this.dia.carreras[x].style =
              "height: " +
              height +
              "pt; min-height: " +
              altoBloqueHorario +
              "pt;position: absolute;left: " +
              left +
              "pt;width:" +
              width +
              "pt;top:" +
              top +
              "pt;";
            this.dia.carreras[x].hora =
              (d.getHours() < 10 ? "0" : "") +
              d.getHours() +
              ":" +
              (d.getMinutes() < 10 ? "0" : "") +
              d.getMinutes();
            oldHora = d.getHours();
          }
        }
        /**
         * Se continua revisando los valores existentes para combustibles
         */
        this.dia.combustibledet = [];
        if (this.dia.combustible[0]) {
          for (let x = 0; x < this.dia.combustible[0].detalles.length; x++) {
            this.dia.combustibledet = agregarYOrdenarPorFechaYTipo(
              this.dia.combustibledet,
              this.dia.combustible[0].detalles[x],
              ["detalle"],
              "start"
            );
          }
          oldHora = 0;
          left = 130;
          for (let x = 0; x < this.dia.combustibledet.length; x++) {
            this.dia.combustibledet[x].bloques = 1 + x;

            let d = new Date(this.dia.combustibledet[x].start);
            let minuto = d.getMinutes();
            let width = 70;
            if (minuto > 0) {
              let a = Math.round(60 / d.getMinutes(), 2);
              minuto = altoBloqueHorario / a;
            }
            let top =
              altoBloqueHorario + altoBloqueHorario * d.getHours() * 2 + minuto;
            let height = altoBloqueHorario;
            if (oldHora == d.getHours() && oldHora != 0) {
              left += 10;
            } else {
              left = 130;
            }
            this.dia.combustibledet[x].style =
              "height: " +
              height +
              "pt; min-height: " +
              altoBloqueHorario +
              "pt;position: absolute;left: " +
              left +
              "pt;width: " +
              width +
              "pt;top:" +
              top +
              "pt;";
            this.dia.combustibledet[x].hora =
              (d.getHours() < 10 ? "0" : "") +
              d.getHours() +
              ":" +
              (d.getMinutes() < 10 ? "0" : "") +
              d.getMinutes();
            oldHora = d.getHours();
          }
        }

        /**
         * Se continua revisando los valores existentes para gastos
         */
        this.dia.gastosdet = [];
        if (obj.gastos[0]) {
          for (let x = 0; x < obj.gastos[0].detalles.length; x++) {
            this.dia.gastosdet = agregarYOrdenarPorFechaYTipo(
              this.dia.gastosdet,
              obj.gastos[0].detalles[x],
              ["detalle"],
              "start"
            );
          }
          oldHora = 0;
          left = 220;
          for (let x = 0; x < this.dia.gastosdet.length; x++) {
            this.dia.gastosdet[x].bloques = 1 + x;
            let d = new Date(this.dia.gastosdet[x].start);
            let minuto = d.getMinutes();
            if (minuto > 0) {
              let a = Math.round(60 / d.getMinutes(), 2);
              minuto = altoBloqueHorario / a;
            }
            let top =
              altoBloqueHorario + altoBloqueHorario * d.getHours() * 2 + minuto;
            let height = 30 + this.dia.gastosdet[x].bloques * 2;
            if (oldHora == d.getHours() && oldHora != 0) {
              left += 10;
            } else {
              left = 220;
            }
            this.dia.gastosdet[x].style =
              "height: " +
              height +
              "pt; min-height: " +
              altoBloqueHorario +
              "pt;position: absolute;left: " +
              left +
              "pt;width: 70pt;top:" +
              top +
              "pt;";
            this.dia.gastosdet[x].hora =
              (d.getHours() < 10 ? "0" : "") +
              d.getHours() +
              ":" +
              (d.getMinutes() < 10 ? "0" : "") +
              d.getMinutes();

            oldHora = d.getHours();
          }
        }

        /**
         * Se continua revisando los valores existentes para TAG
         */

        this.dia.tagdet = [];
        if (obj.tag[0]) {
          for (let x = 0; x < obj.tag[0].detalles.length; x++) {
            this.dia.tagdet = agregarYOrdenarPorFechaYTipo(
              this.dia.tagdet,
              obj.tag[0].detalles[x],
              ["detalle"],
              "start"
            );
          }
          oldHora = 0;
          left = 310;
          for (let x = 0; x < this.dia.tagdet.length; x++) {
            let d = new Date(this.dia.tagdet[x].start);
            let minuto = d.getMinutes();
            if (minuto > 0) {
              let a = 60 / d.getMinutes();
              minuto = altoBloqueHorario / a;
            }
            let top =
              altoBloqueHorario + altoBloqueHorario * d.getHours() * 2 + minuto;
            let height = 15;
            if (oldHora == d.getHours() && oldHora != 0) {
              left += 10;
            } else {
              left = 310;
            }
            this.dia.tagdet[x].style =
              "height: " +
              height +
              "pt; min-height: " +
              altoBloqueHorario +
              "pt;position: absolute;left: " +
              left +
              "pt;width: 90pt;top:" +
              top +
              "pt;";
            this.dia.tagdet[x].class =
              this.dia.tagdet[x].color +
              " text-start pl-2 text-katal-carrera-calendario rounded-lg elevation-6";
            this.dia.tagdet[x].hora =
              (d.getHours() < 10 ? "0" : "") +
              d.getHours() +
              ":" +
              (d.getMinutes() < 10 ? "0" : "") +
              d.getMinutes();

            oldHora = d.getHours();
          }
        }

        /**
         * Se crean los distintos bloques horarios de 30 minutos
         * para dibujar el día separado por horas.
         */
        this.horas = [];
        let top = 0;
        for (let x = 0; x < 48; x++) {
          top += altoBloqueHorario;
          let dia2tmp = {
            hora: x / 2,
            style:
              "height: " +
              altoBloqueHorario +
              "pt; min-height: " +
              altoBloqueHorario +
              "pt;position: absolute;left: 5pt;width: 98%;top:" +
              top +
              "pt;border-bottom: 1px dotted  black;",
          };
          this.horas.push(dia2tmp);
        }
      }
    },
    revisarCarreraDia: function () {
      if (this.carreras) {
        if (this.carreras.length) {
          if (this.filas) {
            for (let x = 0; x < this.filas.length; x++) {
              if (this.filas[x].dias) {
                for (let y = 0; y < this.filas[x].dias.length; y++) {
                  this.filas[x].dias[y] = {
                    dia: this.filas[x].dias[y].dia,
                    eventos: this.filtrarPorDiaEvento(
                      this.filas[x].dias[y].dia
                    ),
                    carreras: this.filtrarPorDiaCarrera(
                      this.filas[x].dias[y].dia
                    ),
                    final: 0,
                    valor_descuento: 0,
                    bencina: 0,
                    color: "bg_green-lighten-5",

                    combustible: [],
                    gastos: [],
                    tag: [],
                    pago: 0,
                  };
                }
              }
            }
          }
        }
      }

      if (this.combustible) {
        if (this.combustible.length) {
          if (this.filas) {
            for (let x = 0; x < this.filas.length; x++) {
              if (this.filas[x].dias) {
                for (let y = 0; y < this.filas[x].dias.length; y++) {
                  this.filas[x].dias[y].combustible =
                    this.filtrarPorDiaCombustible(this.filas[x].dias[y].dia);
                }
              }
            }
          }
        }
      }
      if (this.gastos) {
        if (this.gastos.length) {
          if (this.filas) {
            for (let x = 0; x < this.filas.length; x++) {
              if (this.filas[x].dias) {
                for (let y = 0; y < this.filas[x].dias.length; y++) {
                  this.filas[x].dias[y].gastos = this.filtrarPorDiaGasto(
                    this.filas[x].dias[y].dia
                  );
                }
              }
            }
          }
        }
      }
      if (this.tag) {
        if (this.tag.length) {
          if (this.filas) {
            for (let x = 0; x < this.filas.length; x++) {
              if (this.filas[x].dias) {
                for (let y = 0; y < this.filas[x].dias.length; y++) {
                  this.filas[x].dias[y].tag = this.filtrarPorDiaTag(
                    this.filas[x].dias[y].dia
                  );
                }
              }
            }
          }
        }
      }
      this.valorCarrera = 0;
      this.valorFinal = 0;
      let cantidadCargas = 0;
      this.toleranciaBencina = 0;
      if (this.filas) {
        for (let x = 0; x < this.filas.length; x++) {
          if (this.filas[x].dias) {
            for (let y = 0; y < this.filas[x].dias.length; y++) {
              if (this.filas[x].dias[y].eventos) {
                /**
                 * Carreras
                 */
                for (let z = 0; z < this.filas[x].dias[y].eventos.length; z++) {
                  this.filas[x].dias[y].pago +=
                    this.filas[x].dias[y].eventos[z].monto_pago;
                  this.filas[x].dias[y].valor_descuento +=
                    this.filas[x].dias[y].eventos[z].valor_descuento;
                  this.filas[x].dias[y].final +=
                    this.filas[x].dias[y].eventos[z].monto_total;

                  this.valorCarrera +=
                    this.filas[x].dias[y].eventos[z].monto_pago;
                  this.valorFinal +=
                    this.filas[x].dias[y].eventos[z].monto_total;
                }
                /**
                 * Combustibles
                 */
                if (this.filas[x].dias[y].combustible) {
                  for (
                    let z = 0;
                    z < this.filas[x].dias[y].combustible.length;
                    z++
                  ) {
                    this.filas[x].dias[y].final -=
                      this.filas[x].dias[y].combustible[z].valor;

                    this.toleranciaBencina +=
                      this.filas[x].dias[y].combustible[z].porcentajeconsumo;
                    cantidadCargas++;

                    this.filas[x].dias[y].bencina +=
                      this.filas[x].dias[y].combustible[z].valor;

                    this.valorFinal -=
                      this.filas[x].dias[y].combustible[z].valor;
                  }
                }

                /**
                 * Gastos
                 */
                if (this.filas[x].dias[y].gastos) {
                  for (
                    let z = 0;
                    z < this.filas[x].dias[y].gastos.length;
                    z++
                  ) {
                    this.filas[x].dias[y].final -=
                      this.filas[x].dias[y].gastos[z].valor;

                    this.filas[x].dias[y].bencina +=
                      this.filas[x].dias[y].gastos[z].valor;

                    this.valorFinal -= this.filas[x].dias[y].gastos[z].valor;
                  }
                }

                /**
                 * Tag
                 */
                if (this.filas[x].dias[y].tag) {
                  for (let z = 0; z < this.filas[x].dias[y].tag.length; z++) {
                    this.filas[x].dias[y].final -=
                      this.filas[x].dias[y].tag[z].valor;

                    this.filas[x].dias[y].bencina +=
                      this.filas[x].dias[y].tag[z].valor;

                    this.valorFinal -= this.filas[x].dias[y].tag[z].valor;
                  }
                }
              }
            }
          }
        }
        this.toleranciaBencina = this.toleranciaBencina / cantidadCargas / 100;
        if (!this.toleranciaBencina) {
          this.toleranciaBencina = 1;
        }
      }
    },
    lista24hrs: function (carrera) {
      if (!this.filahoras) {
        this.filahoras = [];
        let semana = this.obtenerDetalleSemana(this.semana);
        var x = 0;
        var y = 0;
        let dias = [];
        if (semana.dias) {
          for (x = 0; x < semana.dias.length; x++) {
            dias.push(semana.dias[x]);
          }
          for (x = 0; x < 24; x++) {
            this.filahoras.push({
              hora: x,
              dias: dias,
            });
          }
        }
      }
    },
    obtenerDetalleSemana: function (semana) {
      if (this.filas) {
        for (var x = 0; x < this.filas.length; x++) {
          if (this.filas[x].semana === semana) {
            return this.filas[x];
          }
        }
      }
      return [];
    },
    lecturaInicial: function () {
      if (!this.filas) {
        this.filas = [];
        let year = parseInt(this.periodo.substring(0, 4));
        let month =
          parseInt(this.periodo.substring(this.periodo.length - 2)) - 1;
        let semanas = this.getWeeksInMonth(year, month);
        for (let x = 0; x < semanas.weeksInMonth; x++) {
          this.filas.push({
            semana: x + semanas.startWeekNumber,
            dias: this.getWeekDates(year, x + semanas.startWeekNumber, month),
          });
        }
        for (let x = 0; x < this.filas.length; x++) {
          for (let y = 0; y < this.filas[x].dias.length; y++) {
            this.filas[x].dias[y] = {
              dia: this.filas[x].dias[y],
              eventos: [],
              valor_descuento: 0,
              final: 0,
              pago: 0,
            };
          }
        }
      }
    },

    filtrarPorDiaEvento: function (numeroDia) {
      let proxyArray = this.carreras;
      if (numeroDia) {
        const objetosFiltrados = [];
        for (let i = 0; i < proxyArray.length; i++) {
          const objeto = proxyArray[i];
          const fechaInicio = new Date(objeto.start);
          const diaInicio = fechaInicio.getDate();
          const mesInicio = fechaInicio.getMonth() + 1; // Sumamos 1 porque los meses van de 0 a 11
          const añoInicio = fechaInicio.getFullYear();

          if (diaInicio === numeroDia) {
            objetosFiltrados.push(objeto);
          }
        }

        // Ordenar objetos filtrados por hora, minuto y segundo de inicio
        objetosFiltrados.sort((a, b) => {
          const fechaInicioA = new Date(a.start);
          const fechaInicioB = new Date(b.start);
          return fechaInicioA - fechaInicioB;
        });

        const agrupadosPorFecha = objetosFiltrados.reduce((acc, obj) => {
          const fecha = obj.start.split(" ")[0]; // Extrae la fecha sin la hora

          if (!acc[fecha]) {
            acc[fecha] = {
              fecha,
              cantidad: 0,
              monto_pago: 0,
              valor_descuento: 0,
              monto_total: 0,
              detalles: [],
            };
          }

          acc[fecha].cantidad += 1;
          acc[fecha].monto_pago += obj.monto_pago;
          acc[fecha].valor_descuento += obj.valor_descuento;
          acc[fecha].monto_total += obj.monto_total;
          acc[fecha].detalles.push(obj);

          return acc;
        }, {});

        const resultado = Object.values(agrupadosPorFecha);

        return resultado;
      } else {
        return [];
      }
    },
    filtrarPorDiaCarrera: function (numeroDia) {
      let proxyArray = this.carreras;
      if (numeroDia) {
        const objetosFiltrados = [];
        for (let i = 0; i < proxyArray.length; i++) {
          const objeto = proxyArray[i];
          const fechaInicio = new Date(objeto.start);
          const diaInicio = fechaInicio.getDate();
          const mesInicio = fechaInicio.getMonth() + 1; // Sumamos 1 porque los meses van de 0 a 11
          const añoInicio = fechaInicio.getFullYear();

          if (diaInicio === numeroDia) {
            objetosFiltrados.push(objeto);
          }
        }

        // Ordenar objetos filtrados por hora, minuto y segundo de inicio
        objetosFiltrados.sort((a, b) => {
          const fechaInicioA = new Date(a.start);
          const fechaInicioB = new Date(b.start);
          return fechaInicioA - fechaInicioB;
        });

        return objetosFiltrados;
      } else {
        return [];
      }
    },
    filtrarPorDiaCombustible: function (numeroDia) {
      let proxyArray = this.combustible;
      if (numeroDia) {
        const objetosFiltrados = [];
        for (let i = 0; i < proxyArray.length; i++) {
          const objeto = proxyArray[i];
          const fechaInicio = new Date(objeto.start);
          const diaInicio = fechaInicio.getDate();
          const mesInicio = fechaInicio.getMonth() + 1; // Sumamos 1 porque los meses van de 0 a 11
          const añoInicio = fechaInicio.getFullYear();

          if (diaInicio === numeroDia) {
            objetosFiltrados.push(objeto);
          }
        }

        // Ordenar objetos filtrados por hora, minuto y segundo de inicio
        objetosFiltrados.sort((a, b) => {
          const fechaInicioA = new Date(a.start);
          const fechaInicioB = new Date(b.start);
          return fechaInicioA - fechaInicioB;
        });

        const agrupadosPorFecha = objetosFiltrados.reduce((acc, obj) => {
          const fecha = obj.start.split(" ")[0]; // Extrae la fecha sin la hora

          if (!acc[fecha]) {
            acc[fecha] = {
              fecha,
              cantidad: 0,
              valor: 0,
              detalles: [],
            };
          }

          acc[fecha].cantidad += 1;
          acc[fecha].valor += obj.valor;
          acc[fecha].detalles.push(obj);

          return acc;
        }, {});

        const resultado = Object.values(agrupadosPorFecha);

        return resultado;
      } else {
        return [];
      }
    },
    filtrarPorDiaGasto: function (numeroDia) {
      let proxyArray = this.gastos;
      if (numeroDia) {
        const objetosFiltrados = [];
        for (let i = 0; i < proxyArray.length; i++) {
          const objeto = proxyArray[i];
          const fechaInicio = new Date(objeto.start);
          const diaInicio = fechaInicio.getDate();
          const mesInicio = fechaInicio.getMonth() + 1; // Sumamos 1 porque los meses van de 0 a 11
          const añoInicio = fechaInicio.getFullYear();

          if (diaInicio === numeroDia) {
            objetosFiltrados.push(objeto);
          }
        }

        // Ordenar objetos filtrados por hora, minuto y segundo de inicio
        objetosFiltrados.sort((a, b) => {
          const fechaInicioA = new Date(a.start);
          const fechaInicioB = new Date(b.start);
          return fechaInicioA - fechaInicioB;
        });

        const agrupadosPorFecha = objetosFiltrados.reduce((acc, obj) => {
          const fecha = obj.start.split(" ")[0]; // Extrae la fecha sin la hora

          if (!acc[fecha]) {
            acc[fecha] = {
              fecha,
              cantidad: 0,
              valor: 0,
              detalles: [],
            };
          }

          acc[fecha].cantidad += 1;
          acc[fecha].valor += obj.valor;
          acc[fecha].detalles.push(obj);

          return acc;
        }, {});

        const resultado = Object.values(agrupadosPorFecha);

        return resultado;
      } else {
        return [];
      }
    },
    filtrarPorDiaGastoDetalle: function (numeroDia) {
      let proxyArray = this.gastos;
      if (numeroDia) {
        const objetosFiltrados = [];
        for (let i = 0; i < proxyArray.length; i++) {
          const objeto = proxyArray[i];
          const fechaInicio = new Date(objeto.start);
          const diaInicio = fechaInicio.getDate();
          const mesInicio = fechaInicio.getMonth() + 1; // Sumamos 1 porque los meses van de 0 a 11
          const añoInicio = fechaInicio.getFullYear();

          if (diaInicio === numeroDia) {
            objetosFiltrados.push(objeto);
          }
        }

        // Ordenar objetos filtrados por hora, minuto y segundo de inicio
        objetosFiltrados.sort((a, b) => {
          const fechaInicioA = new Date(a.start);
          const fechaInicioB = new Date(b.start);
          return fechaInicioA - fechaInicioB;
        });

        return objetosFiltrados;
      } else {
        return [];
      }
    },
    filtrarPorDiaTag: function (numeroDia) {
      let proxyArray = this.tag;
      if (numeroDia) {
        const objetosFiltrados = [];
        for (let i = 0; i < proxyArray.length; i++) {
          const objeto = proxyArray[i];
          const fechaInicio = new Date(objeto.start);
          const diaInicio = fechaInicio.getDate();
          const mesInicio = fechaInicio.getMonth() + 1; // Sumamos 1 porque los meses van de 0 a 11
          const añoInicio = fechaInicio.getFullYear();

          if (diaInicio === numeroDia) {
            objetosFiltrados.push(objeto);
          }
        }

        // Ordenar objetos filtrados por hora, minuto y segundo de inicio
        objetosFiltrados.sort((a, b) => {
          const fechaInicioA = new Date(a.start);
          const fechaInicioB = new Date(b.start);
          return fechaInicioA - fechaInicioB;
        });

        const agrupadosPorFecha = objetosFiltrados.reduce((acc, obj) => {
          const fecha = obj.start.split(" ")[0]; // Extrae la fecha sin la hora

          if (!acc[fecha]) {
            acc[fecha] = {
              fecha,
              cantidad: 0,
              valor: 0,
              detalles: [],
            };
          }

          acc[fecha].cantidad += 1;
          acc[fecha].valor += obj.valor;
          acc[fecha].detalles.push(obj);

          return acc;
        }, {});

        const resultado = Object.values(agrupadosPorFecha);

        return resultado;
      } else {
        return [];
      }
    },
    getWeeksInMonth: function (year, month) {
      // El mes en JavaScript es 0-indexed (0 para enero, 1 para febrero, etc.)
      let firstDay = new Date(year, month, 1);
      let lastDay = new Date(year, month + 1, 0);

      // Día de la semana del primer y último día del mes (0 para domingo, 1 para lunes, etc.)
      let firstDayOfWeek = firstDay.getDay();
      let lastDayOfWeek = lastDay.getDay();

      // Ajuste para que las semanas empiecen en lunes (en lugar de domingo)
      firstDayOfWeek = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;
      lastDayOfWeek = lastDayOfWeek === 0 ? 6 : lastDayOfWeek - 1;

      // Calcula la cantidad total de días en el mes
      let daysInMonth = lastDay.getDate();

      // Número de semanas completas en el mes
      let weeksInMonth = Math.ceil((daysInMonth + firstDayOfWeek) / 7);

      // Calcular el número de semana del año para el primer día del mes
      let startWeekNumber = this.getWeekNumber(firstDay);

      return {
        weeksInMonth: weeksInMonth,
        startWeekNumber: startWeekNumber,
      };
    },
    getWeekNumber: function (date) {
      // Copia la fecha y ajústala al jueves de esa semana
      let tempDate = new Date(date.getTime());
      tempDate.setHours(0, 0, 0, 0);
      tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7));

      // Primer jueves del año
      let week1 = new Date(tempDate.getFullYear(), 0, 4);

      // Calcular el número de semanas desde la primera semana del año
      return (
        1 +
        Math.round(
          ((tempDate.getTime() - week1.getTime()) / 86400000 -
            3 +
            ((week1.getDay() + 6) % 7)) /
            7
        )
      );
    },
    getWeekDatesDate: function (year, weekNumber, month) {
      // Crear una fecha para el primer día del año
      let firstDayOfYear = new Date(year, 0, 1);

      // Calcular el primer lunes del año
      let firstMondayOfYear = firstDayOfYear;
      while (firstMondayOfYear.getDay() !== 1) {
        firstMondayOfYear.setDate(firstMondayOfYear.getDate() + 1);
      }

      // Calcular el lunes de la semana dada
      let weekStartDate = new Date(firstMondayOfYear);
      weekStartDate.setDate(weekStartDate.getDate() + (weekNumber - 1) * 7);

      // Crear un arreglo para las fechas de la semana
      let weekDates = [];
      for (let i = 0; i < 7; i++) {
        let currentDate = new Date(weekStartDate);
        currentDate.setDate(weekStartDate.getDate() + i);

        // Verificar si la fecha corresponde al mes especificado
        if (currentDate.getMonth() === month) {
          weekDates.push(currentDate.toISOString().split("T")[0]);
        } else {
          weekDates.push("");
        }
      }

      return weekDates;
    },
    getWeekDates: function (year, weekNumber, month) {
      // Crear una fecha para el primer día del año
      let firstDayOfYear = new Date(year, 0, 1);

      // Calcular el primer lunes del año
      let firstMondayOfYear = firstDayOfYear;
      while (firstMondayOfYear.getDay() !== 1) {
        firstMondayOfYear.setDate(firstMondayOfYear.getDate() + 1);
      }

      // Calcular el lunes de la semana dada
      let weekStartDate = new Date(firstMondayOfYear);
      weekStartDate.setDate(weekStartDate.getDate() + (weekNumber - 1) * 7);

      // Crear un arreglo para las fechas de la semana
      let weekDates = [];
      for (let i = 0; i < 7; i++) {
        let currentDate = new Date(weekStartDate);
        currentDate.setDate(weekStartDate.getDate() + i);

        // Verificar si la fecha corresponde al mes especificado
        if (currentDate.getMonth() === month) {
          weekDates.push(currentDate.getDate());
        } else {
          weekDates.push("");
        }
      }

      return weekDates;
    },
    getDayOfWeekAndDaysInMonth: function (dateString) {
      // Crear un objeto Date a partir de la cadena de fecha
      const date = new Date(dateString);

      // Obtener el día de la semana (0 para domingo, 1 para lunes, etc.)
      const daysOfWeek = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
      const dayOfWeek = daysOfWeek[date.getDay()];

      // Obtener el año y mes de la fecha
      const year = date.getFullYear();
      const month = date.getMonth();

      // Calcular la cantidad de días en el mes
      const daysInMonth = new Date(year, month + 1, 0).getDate();

      return {
        dayOfWeek: dayOfWeek,
        daysInMonth: daysInMonth,
      };
    },
    obtenerDiasEnMes: function (aaaamm) {
      // Extraer el año y el mes del string
      const año = parseInt(aaaamm.substring(0, 4), 10);
      const mes = parseInt(aaaamm.substring(4, 6), 10);

      // Validar que el mes sea válido
      if (mes < 1 || mes > 12) {
        throw new Error("Mes inválido. Debe estar entre 01 y 12.");
      }

      // Crear una fecha del primer día del mes siguiente y restar un día
      const fecha = new Date(año, mes, 0);

      // Devolver el número de días en el mes
      return fecha.getDate();
    },
  },
};
</script>

<style>
.min-height-row td {
  min-height: 100px; /* Ajusta este valor a la altura mínima deseada */
  height: 100px; /* Necesario para que min-height tenga efecto en <td> */
}
.fixed-height-row td,
.fixed-height-row th,
.fixed-height-row tr {
  height: 40px; /* Ajusta este valor a la altura deseada */
  min-height: 40px;
  max-height: 40px;
}
</style>