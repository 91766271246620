<template>
  <v-app>
    <v-main>
      <menuSuperior 
            :user="user"
            :usuarioId="usuarioId"
            @validarMensaje="validarMensaje"
            @setUsuario="setUsuario"
            @setFullLoading="setFullLoading"
            @SalirSistema="SalirSistema"></menuSuperior>
      <v-container align="center">
        <!-- Contenido de tu aplicación aquí -->
        <v-card class="elevation-6" max-width="1300">
          <router-view
            :user="user"
            :usuarioId="usuarioId"
            @validarMensaje="validarMensaje"
            @setUsuario="setUsuario"
            @setFullLoading="setFullLoading"
            @SalirSistema="SalirSistema"
          />
        </v-card>
        <!-- Más contenido aquí para demostrar el scroll -->
        <v-footer align="right" class="text-caption"
          >Entorno ({{ entorno }}) - Versión ({{ version }})</v-footer
        >
      </v-container>
    </v-main>

    <v-dialog
      persistent
      width="auto"
      max-width="70%"
      v-if="dialogEvalMessage"
      v-model="dialogEvalMessage"
    >
      <v-card class="elevation-6 mt-10">
        <v-toolbar :title="msg.title"
          >{{ msg.code }} {{ msg.number ? " : " + msg.number : "" }}</v-toolbar
        >
        <v-divider class="mt-3"></v-divider>
        <v-card-text>
          {{ msg.text }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col cols="12" align="center">
              <v-btn variant="outlined" class="bg-blue" @click="cerrar"
                >Aceptar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      width="auto"
      v-if="fullLoading"
      v-model="fullLoading"
      class="text-center"
    >
      <div>
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div>{{ textoFullLoading }}</div>
    </v-dialog>
  </v-app>
</template>

<script>
import { es } from "vuetify/locale";
import menuSuperior from "@/components/Usuario/principal/menuSuperior.vue";

export default {
  name: "App",
  locale: {
    locale: "es",
    fallback: "es",
    messages: { es },
  },
  data: () => ({
    user: {
      token: null,
      name: null,
      usuarioId: null,
      options: [],
    },
    entorno: process.env.NODE_ENV,
    version: process.env.VUE_APP_VERSION,
    msg: {
      number: null,
      code: null,
      title: null,
      text: null,
    },
    dialogEvalMessage: false,
    fullLoading: false,
    textoFullLoading: "",
  }),
  components: {
    menuSuperior,
  },
  mounted: function () {
    if (sessionStorage.getItem("user")) {
      this.user = JSON.parse(sessionStorage.getItem("user"));
    }
  },
  methods: {
    setUsuario: async function (usuario) {
      console.log(usuario);
      if (typeof usuario == "object" && usuario != null) {
        this.user.token = usuario.token;
        this.user.name = usuario.nombre;
        this.user.usuarioId = usuario.id;
        this.user.options = usuario.opciones_menu;
        const parsed = JSON.stringify(this.user);
        sessionStorage.setItem("user", parsed);
      } else {
        sessionStorage.setItem("user", null);
        sessionStorage.removeItem("user");
      }
    },
    setFullLoading: async function (visible, texto) {
      if (visible) {
        this.textoFullLoading = texto;
      }
      this.fullLoading = visible;
    },
    validarMensaje: async function (msgAjax) {
      if (typeof msgAjax == "object") {
        this.msg.number = msgAjax.error;
        this.msg.code = msgAjax.code;
        this.msg.title = msgAjax.title;
        this.msg.text = msgAjax.message;
      } else {
        this.msg.number = "0000";
        this.msg.code = "0000";
        this.msg.title = "ERROR INESPERADO";
        this.msg.text =
          "El mensaje de error no pudo ser interpretado correctamente, el sistema se cerrará. Por favor volver a ingresar.";
      }
      this.dialogEvalMessage = true;
    },
    cerrar: async function () {
      this.dialogEvalMessage = false;
      if (this.msg.number == 1000) {
        this.SalirSistema();
      }
    },
    SalirSistema: function () {
      this.user = {
        token: null,
        name: null,
        usuarioId: null,
      };
      this.setUsuario(this.user);
      this.dialogEvalMessage = false;
      this.fullLoading = false;
      this.textoFullLoading = "";
      this.$router.push("/");
    },
  },
};
</script>

<style>
.captcha,
.v-label,
.v-field-label,
.label,
.v-label--clickable,
.v-selection-control {
  font-size: 12px !important;
}

.v-label {
  font-size: 12px;
}
.v-input__details {
  font-size: 12px;
}
.v-field__input {
  font-size: 12px;
}
.cardColor {
  background-color: white !important;
  border-color: transparent !important;
  opacity: 0.8;
}

.border-col {
  padding: 3px;
}

.gradient-background {
  background: linear-gradient(to bottom, white, #00acc1);
  justify-content: center;
  align-items: top;
}

.gradient-menu {
  background: linear-gradient(to top, white, #4dd0e1);
  justify-content: center;
  align-items: top;
}

:root {
  --font-size-title1: 20pt;
  --font-size-title2: 16pt;
  --font-size-title3: 14pt;
  --font-size-title4: 12pt;
  --font-size-title5: 10pt;
  --font-size-normal1: 9pt;
  --font-size-normal2: 8pt;
  --font-size-normal2: 7pt;
  --color-title-beautiful: #006064;
  --color-border-title: white;
  --color-title-beautiful-3: black;
  --color-title: #black;
  --color-subtitle: #black;
  --color-subtitle3: #black;
  --color-text-comun: #black;
  --family: "Roboto", sans-serif;
}

.text-katal-title-beautiful {
  font-family: var(--family);
  font-size: var(--font-size-title1);
  color: var(--color-title-beautiful);
  /*font-weight: bold;
  -webkit-text-stroke: 1px var(--color-border-title); /* Ancho y color del borde del texto */
}
.text-katal-title {
  font-family: var(--family);
  font-size: var(--font-size-title1);
  color: var(--color-title);
  font-weight: bold;
}
.text-katal-subtitle-beautiful {
  font-family: var(--family);
  font-size: var(--font-size-title2);
  color: var(--color-title-beautiful);
  /*font-weight: bold;
  -webkit-text-stroke: 0.5px var(--color-border-title); /* Ancho y color del borde del texto */
}
.text-katal-subtitle {
  font-family: var(--family);
  font-size: var(--font-size-title2);
  color: var(--color-subtitle);
  font-weight: bold;
}
.text-katal-title-beautiful3 {
  font-family: var(--family);
  font-size: var(--font-size-title3);
  /*color: var(--color-title-beautiful-3);
  font-weight: bold;
  -webkit-text-stroke: 0.5px var(--color-border-title); /* Ancho y color del borde del texto */
}

.text-katal-title3 {
  font-family: var(--family);
  font-size: var(--font-size-title4);
  color: var(--color-subtitle3);
  font-weight: bold;
}

.text-katal-carrera-calendario {
  font-family: var(--family);
  font-size: var(--font-size-normal1);
  color: var(--color-text-comun);
}

.calendario-container {
  overflow-x: hidden;
}

@media (max-width: 600px) {
  .text-katal-title-beautiful {
    font-family: var(--family);
    font-size: var(--font-size-title2);
    color: var(--color-title-beautiful);
    /*font-weight: bold;
    -webkit-text-stroke: 0.5px var(--color-border-title); /* Ancho y color del borde del texto */
  }

  .text-katal-title {
    font-family: var(--family);
    font-size: var(--font-size-title2);
    color: var(--color-title);
    font-weight: bold;
  }
  .text-katal-subtitle-beautiful {
    font-family: var(--family);
    font-size: var(--font-size-title3);
    color: var(--color-title-beautiful);
    /*font-weight: bold;
    -webkit-text-stroke: 0.2px var(--color-border-title); /* Ancho y color del borde del texto */
  }
  .text-katal-subtitle {
    font-family: var(--family);
    font-size: var(--font-size-title3);
    color: var(--color-subtitle);
    font-weight: bold;
  }
  .text-katal-title-beautiful3 {
    font-family: var(--family);
    font-size: var(--font-size-title4);
    /*color: var(--color-title-beautiful-3);
    font-weight: bold;
    -webkit-text-stroke: 0.2px var(--color-border-title); /* Ancho y color del borde del texto */
  }

  .text-katal-title3 {
    font-family: var(--family);
    font-size: var(--font-size-title5);
    color: var(--color-subtitle3);
    font-weight: bold;
  }

  .text-katal-carrera-calendario {
    font-family: var(--family);
    font-size: var(--font-size-normal3);
    color: var(--color-text-comun);
  }

  .calendario-container {
    overflow-x: auto;
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .text-katal-carrera-calendario {
    font-family: var(--family);
    font-size: var(--font-size-normal2);
    color: var(--color-text-comun);
  }
}
</style>