<template>
    <div class="bg-red-lighten-4 text-start pl-2 text-katal-carrera-calendario rounded-lg elevation-6" max-width="100" @click="verDetalle">
      <v-icon>mdi-boom-gate-up-outline</v-icon>
      C:{{ tag.cantidad }}<br>
      V:{{ formatoMonedaChile(-tag.valor) }}
    <!--v-tooltip activator="parent" location="bottom">
        <v-card class="ma-3">
          <v-card-title>{{ carrera.comprobante }}</v-card-title>
          <v-card-text>
            <table>
              <tr>
                <td>Valor Carrera:</td>
                <td align="right">{{ formatoMonedaChile(carrera.monto_pago) }}</td>
              </tr>
              <tr>
                <td>Descuento:</td>
                <td align="right">{{ formatoMonedaChile(carrera.valor_descuento) }}</td>
              </tr>
              <tr>
                <td>Valor Final:</td>
                <td align="right">{{ formatoMonedaChile(carrera.monto_total) }}</td>
              </tr>
            </table>
          </v-card-text>
        </v-card>
      </v-tooltip-->
    </div>
  </template>
  
  <script>
  import { formatoMonedaChile } from "@/helpers/funcionesEstandard";
  
  export default {
    data: () => ({
      clase: null,
      formatoMonedaChile: formatoMonedaChile,
    }),
    props: {
        tag: Object,
    },
    mounted: function () {
      this.clase = "rounded-lg cursor-pointer text-katal-carrera-calendario elevation-9 mt-2 " + this.tag.color;
    },
    methods: {
      verDetalle: async function(){
        
      }
    }
  };
  </script>
  
  <style>
  </style>