<template>
  <v-window v-model="onboarding" show-arrows>
    <template v-slot:prev="{ props }">
      <v-btn
      density="default" icon="mdi-page-previous-outline"
        color="success"
        @click="props.onClick"
      >
      </v-btn>
    </template>
    <template v-slot:next="{ props }">
      <v-btn
      density="default" icon="mdi-page-next-outline"
        color="success"
        @click="props.onClick"
      >
      </v-btn>
    </template>
    <v-window-item key="0" class="ma-3">
      <v-form ref="formNew" v-model="validForm" @submit.prevent="submitNew">
        <v-row align="center" justify="center" class="mt-6">
      <v-col :cols="12">
        <v-row align="center" justify="center">
          <v-col cols="12" md="5" class="border-col">
            <formtext
              labelSet="Dirección de Origen"
              iconoSet="mdi-car-back"
              :autofocusSet="true"
              :maxLengthSet="50"
              :minLengthSet="10"
              MensajeAyudaSet="Ingrese dirección de origen [calle - numero - comuna - region]"
              :requeridoSet="true"
              textoAlternativoSet="10 a 50 caracteres"
              :validatingSet="validating"
              :valorSet="form.direccionOrigen"
              ref="direccionOrigen"
            ></formtext>
          </v-col>
          <v-col cols="12" md="5" class="border-col">
            <formtext
              labelSet="Dirección de Destino"
              iconoSet="mdi-car-back"
              :maxLengthSet="50"
              :minLengthSet="10"
              MensajeAyudaSet="Ingrese dirección de destino [calle - numero - comuna - region]"
              :requeridoSet="true"
              textoAlternativoSet="10 a 50 caracteres"
              :validatingSet="validating"
              :valorSet="form.direccionDestino"
              ref="direccionDestino"
            ></formtext>
          </v-col>
          <v-col cols="12" md="2" class="border-col">
            <v-btn prepend-icon="mdi-google-maps" @click="abrirMaps"
              >Buscar</v-btn
            >
          </v-col>
          <v-col cols="6" md="3" class="border-col">
            <formnumero
              :autofocusSet="false"
              labelSet="Kilometros carrera"
              :requeridoSet="true"
              :valorMaximoSet="50"
              :valorMinimoSet="1"
              MensajeAyudaSet="Ingrese la cantidad de kilometros de la carrera"
              textoAlternativoSet="de 1 a 2000"
              preIconoSet="mdi-map-marker-distance"
              :valorSet="form.kilometros"
              @SetValor="calculaCarrera"
              ref="kilometros"
            ></formnumero>
          </v-col>

          <v-col cols="6" md="3" class="border-col">
            <formnumero
              :autofocusSet="false"
              labelSet="Duración carrera"
              :requeridoSet="true"
              :valorMaximoSet="50"
              :valorMinimoSet="1"
              MensajeAyudaSet="Ingrese la duración de la carrera en minutos"
              textoAlternativoSet="de 1 a 2000"
              preIconoSet="mdi-clock-outline"
              :valorSet="form.minutos"
              ref="minutos"
            ></formnumero>
          </v-col>

          <v-col cols="6" md="3" class="border-col">
            <formnumero
              :autofocusSet="false"
              labelSet="Espera del pasajero"
              :requeridoSet="true"
              :valorMaximoSet="50"
              :valorMinimoSet="0"
              MensajeAyudaSet="Ingrese los minutos de espera"
              textoAlternativoSet="de 0 a 120"
              preIconoSet="mdi-timer-off-outline"
              :valorSet="form.espera"
              @SetValor="calculaCarrera"
              ref="espera"
            ></formnumero>
          </v-col>
        </v-row>
      </v-col>
          <v-col
            :cols="widthCol.xs"
            :xl="widthCol.xl"
            :lg="widthCol.lg"
            :md="widthCol.md"
            :sm="widthCol.sm"
            :xs="widthCol.xs"
            class="border-col"
          >
            <formcombobox
              labelSet="Servicio"
              :itemsSet="servicios"
              :autofocusSet="true"
              MensajeAyudaSet="Seleccione el servicio"
              :requeridoSet="true"
              :valorSet="form.Servicio"
              :readonlySet="realOnly"
              @SetValor="SetServicio"
              ref="Servicio"
            ></formcombobox>
          </v-col>
          <v-col
            :cols="widthCol.xs"
            :xl="widthCol.xl"
            :lg="widthCol.lg"
            :md="widthCol.md"
            :sm="widthCol.sm"
            :xs="widthCol.xs"
            class="border-col"
          >
            <formcombobox
              labelSet="Vehiculo"
              :itemsSet="Vehiculos"
              MensajeAyudaSet="Seleccione el servicio"
              :requeridoSet="true"
              :valorSet="form.Vehiculo"
              :readonlySet="realOnly"
              ref="Vehiculo"
            ></formcombobox>
          </v-col>
          <v-col
            :cols="widthCol.xs"
            :xl="widthCol.xl"
            :lg="widthCol.lg"
            :md="widthCol.md"
            :sm="widthCol.sm"
            :xs="widthCol.xs"
            class="border-col"
            v-if="realOnly == false"
          >
            <formarchivo
              labelSet="Imágen Vale"
              MensajeAyudaSet="Máximo 1 fotos de 5MB c/u"
              acceptSet="image/*"
              iconoSet="mdi-camera"
              multipleSet="true"
              :requeridoSet="false"
              textoAlternativoSet="Máximo 1 fotos de 5MB c/u"
              :validatingSet="validating"
              :minFilesSet="1"
              :maxFilesSet="1"
              :autofocusSet="false"
              @SetValor="SetFotoVale"
              ref="imagenVale"
            ></formarchivo>
          </v-col>
          <v-col
            :cols="widthCol.xs"
            :xl="widthCol.xl"
            :lg="widthCol.lg"
            :md="widthCol.md"
            :sm="widthCol.sm"
            :xs="widthCol.xs"
            class="border-col"
          >
            <formtext
              labelSet="Número de vale"
              iconoSet="mdi-car-back"
              :maxLengthSet="9"
              :minLengthSet="3"
              MensajeAyudaSet="Ingrese el número de vale"
              :requeridoSet="true"
              textoAlternativoSet="3 a 9 caracteres"
              :validatingSet="validating"
              @SetValor="SetNumeroVale"
              :readonlySet="realOnly"
              :valorSet="form.numeroVale"
              ref="numeroVale"
            ></formtext>
          </v-col>
          <v-col
            :cols="widthCol.xs"
            :xl="widthCol.xl"
            :lg="widthCol.lg"
            :md="widthCol.md"
            :sm="widthCol.sm"
            :xs="widthCol.xs"
            class="border-col"
          >
            <formfechahora
              :autofocusSet="false"
              labelSet="Fecha/Hora"
              :maxFecSet="MaxFec"
              :minFecSet="MinFec"
              :requeridoSet="true"
              @SetValor="SetFechaHora"
              :valorSet="form.fechahora"
              :readonlySet="realOnly"
              ref="fechaHora"
            ></formfechahora>
          </v-col>
          <v-col
            :cols="widthCol.xs"
            :xl="widthCol.xl"
            :lg="widthCol.lg"
            :md="widthCol.md"
            :sm="widthCol.sm"
            :xs="widthCol.xs"
            class="border-col"
          >
            <formnumero
              :autofocusSet="false"
              labelSet="Valor"
              :requeridoSet="true"
              :valorMaximoSet="10000000"
              :valorMinimoSet="100"
              MensajeAyudaSet="Ingrese el valor total de la carrera"
              textoAlternativoSet="de 100 a 10000000"
              preIconoSet="mdi-currency-usd"
              @SetValor="SetValor"
              :valorSet="form.valor"
              :readonlySet="realOnly"
              ref="valor"
            ></formnumero>
          </v-col>
          <v-col
            :cols="widthCol.xs"
            :xl="widthCol.xl"
            :lg="widthCol.lg"
            :md="widthCol.md"
            :sm="widthCol.sm"
            :xs="widthCol.xs"
            class="border-col"
            v-if="realOnly == true"
          >
            <formnumero
              :autofocusSet="false"
              labelSet="Descuento Servicio"
              :requeridoSet="true"
              :valorMaximoSet="10000000"
              :valorMinimoSet="100"
              MensajeAyudaSet="Descuento del servicio a la carrera"
              textoAlternativoSet="de 100 a 10000000"
              preIconoSet="mdi-currency-usd"
              @SetValor="SetValor"
              :valorSet="form.valor_descuento"
              :readonlySet="realOnly"
              ref="valor"
            ></formnumero>
          </v-col>

          <v-col
            :cols="widthCol.xs"
            :xl="widthCol.xl"
            :lg="widthCol.lg"
            :md="widthCol.md"
            :sm="widthCol.sm"
            :xs="widthCol.xs"
            class="border-col"
            v-if="realOnly == true"
          >
            <formnumero
              :autofocusSet="false"
              labelSet="Valor Final"
              :requeridoSet="true"
              :valorMaximoSet="10000000"
              :valorMinimoSet="100"
              MensajeAyudaSet="Valor final que se pagará por la carrera"
              textoAlternativoSet="de 100 a 10000000"
              preIconoSet="mdi-currency-usd"
              @SetValor="SetValor"
              :valorSet="form.valor_pago"
              :readonlySet="realOnly"
              ref="valor"
            ></formnumero>
          </v-col>
          <v-col
            :cols="widthCol.xs"
            :xl="widthCol.xl"
            :lg="widthCol.lg"
            :md="widthCol.md"
            :sm="widthCol.sm"
            :xs="widthCol.xs"
            class="border-col"
          >
            <formnumero
              :autofocusSet="false"
              labelSet="TAG"
              :requeridoSet="true"
              :valorMaximoSet="50"
              :valorMinimoSet="0"
              MensajeAyudaSet="Ingrese la cantidad de TAG"
              textoAlternativoSet="de 0 a 50"
              preIconoSet="mdi-boom-gate-outline"
              @SetValor="SetTag"
              :valorSet="form.tag"
              :readonlySet="realOnly"
              ref="tag"
            ></formnumero>
          </v-col>
          <v-col
            :cols="widthCol.xs"
            :xl="widthCol.xl"
            :lg="widthCol.lg"
            :md="widthCol.md"
            :sm="widthCol.sm"
            :xs="widthCol.xs"
            class="border-col"
          >
            <formnumero
              :autofocusSet="false"
              labelSet="Pasajeros"
              :requeridoSet="true"
              :valorMaximoSet="50"
              :valorMinimoSet="1"
              MensajeAyudaSet="Ingrese la cantidad de Pasajeros"
              textoAlternativoSet="de 1 a 50"
              preIconoSet="mdi-hail"
              @SetValor="SetPasajero"
              :valorSet="form.pasajeros"
              :readonlySet="realOnly"
              ref="pasajeros"
            ></formnumero>
            {{ readonly }}
          </v-col>
          <v-col
            :cols="widthCol.xs"
            :xl="widthCol.xl"
            :lg="widthCol.lg"
            :md="widthCol.md"
            :sm="widthCol.sm"
            :xs="widthCol.xs"
            class="border-col"
            v-if="realOnly == false"
          >
            <formarchivo
              labelSet="Otras Imagenes de Respaldo"
              MensajeAyudaSet="Máximo 2 fotos de 5MB c/u"
              acceptSet="image/*"
              iconoSet="mdi-camera"
              multipleSet="true"
              :requeridoSet="false"
              textoAlternativoSet="Máximo 2 fotos de 5MB c/u"
              :validatingSet="validating"
              :minFilesSet="1"
              :maxFilesSet="2"
              :autofocusSet="false"
              ref="otrasFotos"
            ></formarchivo>
          </v-col>
          <v-col
            :cols="widthCol.xs"
            :xl="widthCol.xl"
            :lg="widthCol.lg"
            :md="widthCol.md"
            :sm="widthCol.sm"
            :xs="widthCol.xs"
            class="border-col"
          >
            <formCheckBox
              :inlineSet="true"
              :opciones="sino"
              labelSet="¿Efectivo?"
              @SetValor="SetEfectivo"
              :valorSet="form.efectivo"
              ref="efectivo"
              :readonlySet="realOnly"
            ></formCheckBox>
          </v-col>
          <v-col
            :cols="widthCol.xs"
            :xl="widthCol.xl"
            :lg="widthCol.lg"
            :md="widthCol.md"
            :sm="widthCol.sm"
            :xs="widthCol.xs"
            class="border-col"
          >
            <formCheckBox
              :inlineSet="true"
              :opciones="sino"
              labelSet="¿Tarifa Fija?"
              @SetValor="SetTarifaFija"
              :valorSet="form.tarifafija"
              ref="tarifafija"
              :readonlySet="realOnly"
            ></formCheckBox>
          </v-col>
          <v-col cols="12" class="border-col">
            <formtextoGrande
              :autofocusSet="false"
              labelSet="Observación"
              :requeridoSet="false"
              :minLengthSet="0"
              :maxLengthSet="200"
              MensajeAyudaSet="Ingrese observaciones de la carrera"
              textoAlternativoSet="de 0 a 50"
              preIconoSet="mdi-caps-lock"
              :valorSet="form.observacion"
              :readonlySet="realOnly"
              ref="observacion"
            ></formtextoGrande>
          </v-col>
          <v-col cols="12">
            <v-row align="center" justify="center">
              <v-col cols="6" v-if="realOnly == false">
                <formbtnsend
                  labelSet="Guardar"
                  :validating="validating"
                ></formbtnsend>
              </v-col>
              <v-col cols="6">
                <formbtncancel
                  labelSet="Volver"
                  @accion="volver"
                  :validating="validating"
                ></formbtncancel>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-window-item>
    <v-window-item v-for="i in form.img" :key="i.id" class="ma-3">
      <v-row>
        <v-col cols="12">
      <v-img :src="i.img"></v-img>
    </v-col>
              <v-col cols="12">
                <formbtncancel
                  labelSet="Volver"
                  @accion="volver"
                  :validating="validating"
                ></formbtncancel>
              </v-col>
      </v-row>
    </v-window-item>
  </v-window>
</template>
  
  <script>
import {
  obtenerJsonGET,
  enviarJsonPOST,
  formatoFechaHoraVue,
} from "@/helpers/funcionesEstandard";
import formarchivo from "@/components/form/archivos.vue";
import formnumero from "@/components/form/numero.vue";
import formfechahora from "@/components/form/fechaHora.vue";
import formtextoGrande from "@/components/form/textoGrande.vue";
import formCheckBox from "@/components/form/checkBox.vue";
import formcombobox from "@/components/form/combobox.vue";
import formtext from "@/components/form/textos.vue";
import formbtnsend from "@/components/form/buttonSubmitform.vue";
import formbtncancel from "@/components/form/buttonCancelform.vue";

export default {
  data: () => ({
    onboarding: 0,
    carrera: {
      bajada_bandera: 2100,
      val_kilometro: 750,
      minuto_espera_libre: 15,
      val_minuto_espera: 150,
      val_tag: 900,
    },
    widthCol: {
      xl: 2,
      lg: 3,
      md: 4,
      sm: 6,
      xs: 12,
    },
    validating: null,
    validForm: null,
    form: {
      Servicio: null,
      Vehiculo: null,
      fotoVale: null,
      fotos: null,
      numeroVale: null,
      fechahora: null,
      valor: null,
      tag: null,
      pasajeros: 1,
      efectivo: "2",
      tarifafija: "2",
      observacion: "",
      estado_general_id: 0,
      valor_descuento: null,
      valor_pago: null,
      kilometros: null,
      minutos: null,
      espera: null,
      direccionOrigen: null,
      direccionDestino: null,
    },
    MaxFec: null,
    MinFec: null,
    sino: [
      { label: "Si", valor: 1 },
      { label: "No", valor: 2 },
    ],
    servicios: [],
    realOnly: false,
  }),
  watch: {},
  props: {
    id: Number,
  },
  components: {
    formnumero,
    formarchivo,
    formfechahora,
    formtextoGrande,
    formcombobox,

    formtext,
    formbtnsend,
    formbtncancel,
    formCheckBox,
  },
  mounted: async function () {
    var fec = new Date();
    this.MaxFec = formatoFechaHoraVue(fec);
    var fec2 = new Date();
    var numberOfMlSeconds = fec2.getTime();
    var addMlSeconds = 30 * 24 * 60 * 60000;
    var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
    this.MinFec = formatoFechaHoraVue(newDateObj);
    this.setFullLoading(true, "Buscando servicios...");
    this.buttonLoading = true;
    let fd = new FormData();
    var json = await obtenerJsonGET(
      "servicio/obtener_servicios_combobox_empresa",
      null
    );
    if (json.error == 0) {
      if (json.data) {
        this.servicios = json.data;
        if (this.servicios.length == 1) {
          this.form.Vehiculo = this.servicios[0];
        }
      }
    } else {
      this.validarMensaje(json);
    }
    this.setFullLoading(false, null);
    this.obtenerVehiculos();
    this.obtenerCarrera();
  },
  methods: {
    abrirMaps: function () {
      if (
        !isEmpty(this.$refs.direccionOrigen.valor) &&
        !isEmpty(this.$refs.direccionDestino.valor)
      ) {
        let url =
          "https://www.google.com/maps/dir/" +
          this.$refs.direccionOrigen.valor +
          "/" +
          this.$refs.direccionDestino.valor +
          "/";
        console.log(url);
        window.open(url, "_blank");
        this.findAlternativaDistancia();
      } else {
        let obj = {
          error: 1,
          message: "Debe ingresar ambas direcciones.",
          data: null
        };
          this.validarMensaje(obj);
      }
    },
    submitNew: async function () {
      const { valid } = await this.$refs.formNew.validate();
      if (valid) {
        this.validating = true;
        this.setFullLoading(true, "Guardando carrera...");
        if (this.$refs.otrasFotos.valor) {
          for (var x = 0; x < this.$refs.otrasFotos.valor.length; x++) {
            let size = this.$refs.otrasFotos.valor[x].size / 1024 / 1024;
            let date = this.$refs.otrasFotos.valor[x].lastModifiedDate;
            if (size > 5) {
              let obj = {
                error: 1,
                message:
                  "Los archivos adjuntos no pueden superar 5MB, por favor corregir.",
              };
              this.validating = false;
              this.setFullLoading(
                false,
                "Guardando carrera " + this.msgSend + "..."
              );
              this.validarMensaje(obj);
              return false;
            }
          }
        }
        let fd = new FormData();
        fd.append("detalle", this.id);
        fd.append("Servicio", this.$refs.Servicio.valor.cod);
        fd.append("Vehiculo", this.$refs.Vehiculo.valor.cod);

        if (this.$refs.imagenVale.valor) {
          fd.append("fotoVale", this.$refs.imagenVale.valor[0]);
        }
        if (this.$refs.otrasFotos.valor) {
          for (let x = 0; x < this.$refs.otrasFotos.valor.length; x++) {
            fd.append("fotos[]", this.$refs.otrasFotos.valor[x]);
          }
        }
        fd.append("numeroVale", this.$refs.numeroVale.valor);
        fd.append("fechahora", this.$refs.fechaHora.valor);
        fd.append("valor", this.$refs.valor.valor);
        fd.append("tag", this.$refs.tag.valor);
        fd.append("pasajeros", this.$refs.pasajeros.valor);
        fd.append("efectivo", this.$refs.efectivo.valor);
        fd.append("tarifafija", this.$refs.tarifafija.valor);
        fd.append("direccionOrigen", this.$refs.direccionOrigen.valor);
        fd.append("direccionDestino", this.$refs.direccionDestino.valor);
        fd.append("kilometros", this.$refs.kilometros.valor);
        fd.append("minutos", this.$refs.minutos.valor);
        fd.append("espera", this.$refs.espera.valor);
        fd.append("observacion", this.$refs.observacion.valor);


        
        fd.append("valor_kilometro", this.carrera.val_kilometro);
        fd.append("valor_minuto", this.carrera.val_minuto_espera);
        fd.append("valor_tag", this.carrera.val_tag);
        fd.append("bajada_bandera", this.carrera.bajada_bandera);

        var json = await enviarJsonPOST("carrera/modificar", fd);
        if (json.error == 0) {
          this.$router.push("/MenuPrincipal");
          this.validarMensaje(json);
        } else {
          this.validarMensaje(json);
        }
        this.validating = false;
        this.setFullLoading(false, "Guardando carrera " + this.msgSend + "...");
      }
    },
    obtenerVehiculos: async function () {
      this.setFullLoading(true, "Buscando vehículos...");
      this.buttonLoading = true;
      let fd = new FormData();
      var json = await obtenerJsonGET(
        "vehiculo/obtener_listado_vehiculo_usuario",
        null
      );
      if (json.error == 0) {
        if (json.data) {
          this.Vehiculos = json.data;
          if (this.Vehiculos.length == 1) {
            this.form.Vehiculo = this.Vehiculos[0];
          }
        }
      } else {
        this.validarMensaje(json);
      }
      this.setFullLoading(false, null);
    },
    obtenerCarrera: async function () {
      this.setFullLoading(true, "Obteniendo carrera " + this.msgSend + "...");

      let fd = new FormData();
      fd.append("detalle", this.id);

      var json = await enviarJsonPOST("carrera/obtener", fd);
      if (json.error == 0) {
        this.carrera.val_kilometro = !isEmpty(json.data.valor_kilometro)?json.data.valor_kilometro:this.carrera.val_kilometro;
        this.carrera.val_minuto_espera = !isEmpty(json.data.valor_minuto)?json.data.valor_minuto:this.carrera.val_minuto_espera;
        this.carrera.bajada_bandera = !isEmpty(json.data.bajada_bandera)?json.data.bajada_bandera:this.carrera.bajada_bandera;
        this.carrera.val_tag = !isEmpty(json.data.valor_tag)?json.data.valor_tag:this.carrera.val_tag;

        this.form.direccionOrigen = json.data.direccion_origen;
        this.form.direccionDestino = json.data.direccion_destino;

        this.form.kilometros = json.data.kilometros;
        this.form.minutos = json.data.minutos;
        this.form.espera = json.data.espera;

        this.form.Servicio = json.data.servicio;
        this.form.Vehiculo = json.data.vehiculo;
        this.form.numeroVale = json.data.comprobante;
        this.form.fechahora = json.data.fechahora;
        this.MinFec = json.data.fechahora;
        this.form.tarifafija = json.data.tarifa_fija;
        this.form.observacion = json.data.observacion;
        this.form.estado_general_id = json.data.estado_general_id;
        this.form.valor_descuento = json.data.valor_descuento;
        this.form.valor_pago =
          json.data.valor_conductor - json.data.valor_descuento;
        this.form.img = json.data.img;
        this.form.tag = json.data.tag;
        this.form.pasajeros = json.data.cantidad_pasajeros;
        this.form.efectivo = json.data.efectivo;
        this.form.valor = json.data.valor_conductor;

        
        if (
          this.form.estado_general_id == 1000 ||
          this.form.estado_general_id == 0
        ) {
          this.realOnly = true;
        } else {
          this.realOnly = false;
        }
      } else {
        this.validarMensaje(json);
      }
      this.validating = false;
      this.setFullLoading(false, null);
    },
    volver: function () {
      this.$emit("volver");
    },
    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
    setFullLoading: async function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
  },
};
</script>