<template>
  <v-card-text>
    <v-row justify="center" align="center">
      <v-col cols="12" md="3" sm="2" justify="center" align="center">
        <v-img src="/img/LogoSolo.png" width="50%" class="rounded-xl"></v-img>
      </v-col>
      <v-col cols="12" md="9" sm="10">
        <div class="text-katal-title" align="left">
          {{ titulo }}
        </div>
        <div class="text-katal-carrera-calendario" align="left">
          <div v-html="`${descripcion}`"></div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <NuevaCarrera
          :validating="validating"
          @validarMensaje="validarMensaje"
          @setFullLoading="setFullLoading"
          @volver="volver"
          ref="NuevaCarrera"
          v-if="codigo == 'NCarrera'"
        ></NuevaCarrera>
        <NuevoCombustible
          @validarMensaje="validarMensaje"
          @setFullLoading="setFullLoading"
          @volver="volver"
          v-if="codigo == 'NCombustible'"
        >
        </NuevoCombustible>
        <NuevoGasto
          @validarMensaje="validarMensaje"
          @setFullLoading="setFullLoading"
          @volver="volver"
          v-if="codigo == 'NGasto'"
        >
        </NuevoGasto>
        <nuevoPago
          @validarMensaje="validarMensaje"
          @setFullLoading="setFullLoading"
          @volver="volver"
          v-if="codigo == 'NPago'"
        >
        </nuevoPago>
        <NuevaCarrera
          :id="id"
          @validarMensaje="validarMensaje"
          @setFullLoading="setFullLoading"
          @volver="volver"
          v-if="codigo == 'VCarrera'"
        >
        </NuevaCarrera>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import NuevaCarrera from "@/components/carrera/NuevaCarrera.vue";
import NuevoCombustible from "@/components/combustible/NuevoCombustible.vue";
import NuevoGasto from "@/components/gastos/nuevoGasto.vue";
import nuevoPago from "@/components/pago/nuevoPago.vue";
import VerCarrera from "@/components/carrera/verCarrera.vue";

export default {
  data: () => ({
    user: {
      token: null,
      name: null,
      usuarioId: null,
    },
    form: null,
    validForm: null,
    codigo: null,
    msgSend: "",
    titulo: null,
    descripcion: null,
    validating: false,
    id: null,
  }),
  components: {
    NuevaCarrera,
    NuevoCombustible,
    NuevoGasto,
    VerCarrera,
    nuevoPago,
  },
  watch: {
    $route: function () {
      this.cargarFormulario();
    },
  },
  mounted: function () {
    this.cargarFormulario();
  },
  methods: {
    cargarFormulario() {
      this.user = JSON.parse(sessionStorage.getItem("user"));
      if (!this.user) {
        this.$emit("SalirSistema");
      } else if (!this.user.token) {
        this.$emit("SalirSistema");
      } else if (this.$route.params.form) {
        this.codigo = this.$route.params.form;
        switch (this.codigo) {
          case "NCarrera":
            this.titulo = "Nueva carrera.";
            this.descripcion =
              "Acá es donde registrará las carreras que vaya realizando.";
            this.msgSend = "Guardando nueva carrera.";
            break;
          case "NCombustible":
            this.titulo = "Nueva carga de combustible.";
            this.descripcion =
              "Acá es donde registrará las cargas de combuctible que realice en su vehículo.";
            this.msgSend = "Guardando nueva carga de combustible.";
            break;
          case "NGasto":
            this.titulo = "Nuevo Gasto.";
            this.descripcion =
              "Acá se registrarán todos los gastos que se realicen, ya sea en el vehículo o en otro concepto que pueda afectar los ingresos.";
            this.msgSend = "Guardando nueva gasto.";
            break;
          case "NPago":
            this.titulo = "Nuevo Pago.";
            this.descripcion =
              "Acá se registrarán todos los pagos que se realicen a los conductores.";
            this.msgSend = "Guardando nueva gasto.";
            break;
          case "VCarrera":
            if (this.$route.params.id) {
              this.id = this.$route.params.id;
              this.titulo = "Visualizando/Editando carrera ";
              this.descripcion =
                "Dependiendo del estado de la carrera, es si podrá o no modificarla.<br>Considere desplazar a la <B>derecha e izquierda</B> para navegar por las imagenes cargadas.";
              this.msgSend = "Guardando carrera.";
            } else {
              this.$emit("SalirSistema");
            }
            break;
        }
      }
    },
    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
    setFullLoading: async function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
    volver: function () {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>