<template>
  <v-card border="opacity-50 sm" class="mx-auto" rounded="xl" variant="text">
    <v-row class="ma-1">
      <v-col cols="12" class="border-col">
        <div class="text-h6 text-katal-title font-weight-bold border-col">
          Resumen de ingresos
        </div>
        <div
          class="text-caption text-sm-h6 text-blue-accent-1 font-weight-bold border-col"
        >
          <span>Total servicios: </span>
          {{ formatoMonedaChile(totalFinal) }}
        </div>
        <div
          class="text-h6 text-sm-h5 text-blue-darken-4 font-weight-black border-col"
        >
        {{ formatearNumeroComoPorcentaje(porcentajeConductor) }} Conductor:
          {{ formatoMonedaChile(Math.round(totalFinal*porcentajeConductor/100)) }}
          <v-tooltip activator="parent" location="bottom">
            Corresponde al porcentaje que le corresponde al conductor
          </v-tooltip>
        </div>
        <div
            class="text-caption text-sm-h6 text-red-accent-1 font-weight-bold border-col"
        >
        {{cantidadCarreraEfectivo }} carrera<span v-if="cantidadCarreraEfectivo>1">s</span> en efectivo:
          {{ formatoMonedaChile(Math.round(-valorCarreraEfectivo,0)) }}
          <v-tooltip activator="parent" location="bottom">
            Corresponde al dinero recibido en efectivo por el conductor que ya se considera como pagado.
          </v-tooltip>
        </div>
        <div
            class="text-caption text-sm-h6 text-red-accent-1 font-weight-bold border-col"
        >
        {{cantidadPagos }} transferencia<span v-if="cantidadCarreraEfectivo>1">s</span>/Deuda<span v-if="cantidadCarreraEfectivo>1">s</span>:
          {{ formatoMonedaChile(-Math.round(pagos)) }}
          <v-tooltip activator="parent" location="bottom">
            Corresponde al dinero entregado al conductor por sus ingresos y/o deudas comprometidas.
          </v-tooltip>
        </div>
        <div
          class="text-caption text-sm-h6 text-blue-accent-1 font-weight-bold border-col"
        >
          <span v-if="Math.round(totalFinal*porcentajeConductor/100) 
            -Math.round(valorCarreraEfectivo)
            -Math.round(pagos)>0">Pendiente x transf.: </span>
        <span v-if="Math.round(totalFinal*porcentajeConductor/100) 
            -Math.round(valorCarreraEfectivo)
            -Math.round(pagos)<0">Transferido de mas: </span>
            <span v-if="Math.round(totalFinal*porcentajeConductor/100) 
            -Math.round(valorCarreraEfectivo)
            -Math.round(pagos)==0">Liquidado: </span>
          {{ formatoMonedaChile(Math.round(totalFinal*porcentajeConductor/100) 
            -Math.round(valorCarreraEfectivo)
            -Math.round(pagos))}}
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { formatoMonedaChile,formatearNumeroComoPorcentaje } from "@/helpers/funcionesEstandard";
export default {
  data: () => ({
    formatoMonedaChile: formatoMonedaChile,
    formatearNumeroComoPorcentaje: formatearNumeroComoPorcentaje,
  }),
  props: {
    totalFinal: Number,
    porcentajeConductor: Number,
    valorCarreraEfectivo: Number,
    cantidadCarreraEfectivo: Number,
    cantidadPagos: Number,
    pagos: Number,
  },
};
</script>

<style>
</style>