<template>
  <v-dialog v-model="dia" width="800" height="500">
    <v-card>
      <v-card-title class="headline">Instrucciones de Ruta</v-card-title>
      <v-card-text>
        <div v-for="pa in pasos" :key="pa">
            <div v-html="`${pa.html_instructions} - (${pa.distancia} - ${pa.duracion})`"></div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="cerrarMap">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dia: false,
    map: null,
    directionsService: null,
    directionsRenderer: null,
  }),
  props: {
    dialog: Boolean,
    pasos: Object,
  },
  watch: {
    pasos: function(){
        this.escribirRuta();
    },
    dialog: function(){
        this.dia = this.dialog;
        this.escribirRuta();
    }
  },
  methods: {
    cerrarMap: function () {
      this.$emit("cerrarMap");
    },
    escribirRuta: function(){
        console.log(this.pasos);

    }
  },
};
</script>

<style>
#map {
  width: 100%;
  height: 500px;
}
</style>