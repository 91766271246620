<template>
  <div class="text-md-center pa-4">
    <v-dialog v-model="dialog" transition="dialog-top-transition" fullscreen>
      <template v-slot:activator="{ props: activatorProps }">
        <span  class="d-md-block d-none">
        <v-btn
          prepend-icon="mdi-chat-question-outline"
          size="small"
          text="FAQs"
          v-bind="activatorProps"
        ></v-btn>
        </span> 
        <span class="d-block d-md-none text-caption cursor-pointer" @click="dialog = !dialog">
            <v-icon>mdi-chat-question-outline</v-icon>
            FAQs</span>
      </template>

      <v-card>
        <v-toolbar class="gradient-background_menu_superior">
          <v-btn icon="mdi-close" @click="dialog = false"></v-btn>

          <v-toolbar-title>
            <span class="text-h5 display-1 font-weight-bold"
              >Preguntas Frecuentes</span
            >
          </v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container class="bg-blue-lighten-5 mt-2">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-title
                >Donde damos nuestro servicios?</v-expansion-panel-title
              >
              <v-expansion-panel-text class="text-caption">
                Nuestorts servicios son dados en la cuidad de Santiago, por lo que el origen o el destino debe están dentro de la Región Metropolitana.
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-title
                >¿Qué tipos de servicios ofrecen?</v-expansion-panel-title
              >
              <v-expansion-panel-text class="text-caption">
                Ofrecemos servicios de radiotaxi privado para traslados de pasajeros dentro y fuera de la
                ciudad, viajes al aeropuerto, servicios corporativos y más.
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-title
                >¿Tienen servicios para empresas?</v-expansion-panel-title
              >
              <v-expansion-panel-text class="text-caption">
                Sí, ofrecemos servicios corporativos con facturación mensual y
                atención prioritaria para empresas.
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-title
                >¿Pueden recogerme en mi ubicación
                actual?</v-expansion-panel-title
              >
              <v-expansion-panel-text class="text-caption">
                Sí, solo necesitas indicarnos tu ubicación actual al hacer la
                reserva y uno de nuestros taxis estará en camino.
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-title
                >Reservas y Tarifas</v-expansion-panel-title
              >
              <v-expansion-panel-text class="text-caption">
                <h3>¿Cómo puedo reservar un taxi?</h3>
                Puedes reservar un taxi a través de nuestra aplicación móvil,
                nuestro sitio web o llamando a nuestro número de contacto.
                <h3>¿Cuáles son sus tarifas?</h3>
                Nuestras tarifas varían según la distancia y el tiempo del
                recorrido. Puedes consultar las tarifas estimadas en nuestra
                aplicación o sitio web.
                <h3>¿Ofrecen tarifas planas para ciertos destinos?</h3>
                Sí, tenemos tarifas planas para viajes al aeropuerto y otras
                rutas populares. Consulta nuestra página de tarifas para más
                información.
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-title
                >Seguridad y Comodidad</v-expansion-panel-title
              >
              <v-expansion-panel-text class="text-caption">
                <h3>¿Cómo garantizan la seguridad de los pasajeros?</h3>
                Todos nuestros conductores están certificados y nuestros
                vehículos son inspeccionados regularmente. También contamos con
                un sistema de seguimiento GPS para mayor seguridad.
                <h3>¿Puedo solicitar un vehículo específico?</h3>
                Sí, puedes solicitar vehículos con características específicas,
                como autos con aire acondicionado, espacio adicional para
                equipaje, etc.
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-title>Pagos</v-expansion-panel-title>
              <v-expansion-panel-text class="text-caption">
                <h3>¿Qué métodos de pago aceptan?</h3>
                Aceptamos pagos en efectivo, tarjetas de crédito/débito, y pagos
                electrónicos a través de nuestra aplicación.
                <h3>¿Puedo obtener un recibo de mi viaje?</h3>
                Sí, puedes solicitar un recibo al conductor o recibirlo
                electrónicamente si reservas a través de nuestra aplicación o
                sitio web.
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-title
                >Atención al Cliente</v-expansion-panel-title
              >
              <v-expansion-panel-text class="text-caption">
                <h3>¿Cómo puedo contactar con atención al cliente?</h3>
                Puedes contactarnos a través de nuestro número de atención al
                cliente, correo electrónico o redes sociales.
                <h3>¿Qué hago si olvidé algo en el taxi?</h3>
                Si olvidaste algo en uno de nuestros taxis, contáctanos lo antes
                posible con los detalles del viaje y haremos lo posible para
                recuperarlo.
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-title>Otros</v-expansion-panel-title>
              <v-expansion-panel-text class="text-caption">
                <h3>¿Puedo solicitar un taxi para otra persona?</h3>
                Sí, puedes reservar un taxi para otra persona proporcionando su
                ubicación y destino al momento de la reserva.
                <h3>¿Ofrecen servicios especiales para eventos?</h3>
                Sí, ofrecemos servicios de transporte para eventos especiales
                como bodas, conferencias, y otros eventos corporativos.
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    };
  },
};
</script>
  
  <style>
</style>